@import '~styles/index.scss';

.tabsContainer {
  @include main-font;

  display: flex;

  .tabsItem {
    margin-right: 24px;
    padding-bottom: 4px;
    font-family: $ff-lato;
    font-size: $fs-small;
    font-style: normal;
    font-weight: $fw-normal;
    line-height: 18px;
    text-align: center;
    color: $color-gray;
    cursor: pointer;

    &Active {
      border-bottom: 2px solid map-get($colors, light-blue);
      color: $color-white;
    }
  }

  &.tabsGeneral {
    .tabsItem {
      margin-right: 0;
      padding: 12px 24px;
      font-size: $fs-regular;
      line-height: 22px;
      border-bottom: 2px solid map-get($colors, $color-gray);

      &Active {
        border-bottom: 2px solid map-get($colors, light-blue);
        color: $color-black;
      }
    }
  }

  sup {
    margin-left: 4px;
    color: map-get($colors, light-blue);
  }
}
