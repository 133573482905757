@import '~styles/index.scss';

.conciergeNavigation {
  display: flex;
  height: 100%;
  align-items: center;

  &List {
    display: flex;
    height: 100%;
  }

  &Item {
    height: 100%;
  }

  &LogoutBlock {
    cursor: pointer;
  }
}

.conciergeNavLink {
  @include main-font;

  display: flex;
  height: 100%;
  padding: 10px 20px;
  text-decoration: none;
  border-bottom: 3px solid transparent;
  color: map-get($colors, font-gray);
  align-items: center;

  &:hover {
    color: map-get($colors, white);
  }

  &Active {
    border-bottom: 3px solid map-get($colors, light-blue);
    color: map-get($colors, white);
  }

  &Img {
    height: 20px;
    margin-right: 15px;
  }

  &Tooltip {
    font-family: $ff-lato;
    font-size: map-get($sizes, smaller);
  }
}

.conciergeLogoutButtonImg {
  height: 18px;
}

.conciergeQAButtonImg {
  height: 20px;
}
