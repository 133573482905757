@import '~styles/index.scss';

.conciergeApp {
  .conciergeReportsPage {
    height: calc(100% - #{$header-height});
    min-height: calc(100vh - #{$header-height});
  }
}

.conciergeApp.withReports {
  .conciergeReportsPage {
    height: calc(100% - #{$header-height-with-reports});
    min-height: calc(100vh - #{$header-height-with-reports});
  }
}

.conciergeReportsPage {
  &Main {
    @include horizontally-centered;

    padding: 16px 0;
  }

  &LoadingPanel {
    @include main-font(map-get($sizes, bigger));

    width: 100%;
    text-align: center;
    color: map-get($colors, pink);
  }
}

.conciergeReportsTable {
  overflow-x: auto;

  .rt-th {
    display: flex;
    height: 75px;
    white-space: normal;
    align-items: center;
  }

  .rt-td {
    line-height: 24px;
    white-space: break-spaces;
  }

  .rt-noData {
    top: 80% !important;
  }

  .rt-table {
    overflow: visible;
  }

  .rt-tbody {
    overflow: visible;
    line-height: 17px;
  }

  .ellipsis {
    overflow: hidden !important;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .alignment {
    white-space: normal;
  }

  &NoHeader {
    .rt-thead {
      display: none;
    }

    .rt-noData {
      top: 50% !important;
    }
  }
}

.reportingNavigationContainer {
  display: flex;
  min-height: 40px;
  align-items: center;
  justify-content: space-between;

  .conciergeInputLabel {
    color: $color-white;
  }

  .conciergeInput {
    height: 34px;
    font-size: $fs-small;
  }
}

.reportingTimesNavigationContainer {
  display: flex;
  min-height: 70px;
  align-items: center;
  justify-content: space-between;

  .tabsItemActive {
    color: $color-black;
  }
}

.conciergeReportsTableContainer {
  padding-top: 20px;
}
