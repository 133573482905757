.packageItemsPrice {
  margin-right: 15px;
  font-size: map-get($sizes, smaller);
  text-decoration: line-through;
  color: map-get($colors, warning-red);
}

.packageItemsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .packageItemsTitle {
    margin-top: 0.5em;
    margin-left: 0;
  }

  span {
    margin-top: 2px;
    margin-left: 1em;
    font-family: $ff-lato;
    font-size: map-get($sizes, smaller);
    color: map-get($colors, silver-gray);
  }
}
