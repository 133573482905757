@import '~styles/index.scss';

.loader {
  display: block;
  padding: 0 100px;
  text-align: center;
}

.label {
  @include main-font;

  display: block;
  padding: 8px 0;
  font-size: map-get($sizes, smaller);
  line-height: 18px;
  color: map-get($colors, main-black);

  &City {
    display: inline-block;
    width: 246px;
  }

  &Zipcode {
    display: inline-block;
    width: 246px;
    margin-right: 12px;
  }
}

.makeModelYearRow {
  display: flex;
  width: 100%;
  justify-content: space-between;

  .label {
    width: 30%;
  }
}

.select {
  @include main-font;

  color: map-get($colors, main-black);

  :global(.StyledSelect__menu) {
    height: 150px;
  }
}

.input {
  @include sizing(100% 48px);
  @include main-font;

  width: 100%;
  padding: 14px 16px;
  font-size: map-get($sizes, regular);
  line-height: 20px;
  border: 1px solid map-get($colors, darker-gray);
  border-radius: 6px;
  color: map-get($colors, main-black);
  background-color: map-get($colors, white);

  span {
    margin-bottom: 4px;
  }

  &.error {
    border-color: map-get($colors, pink);
  }

  &Group {
    display: flex;
  }

  &Group & {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &Button {
    padding: 15px;
    border: 1px solid map-get($colors, darker-gray);
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    cursor: pointer;
  }
}

.statusBar {
  max-width: 1120px;
  margin: 31px auto 0;
  padding: 8px 0;
  text-align: center;
  border-radius: 10px;
  color: map-get($colors, white);
  box-shadow: 0 12px 30px 0 rgba(0, 0, 0, 0.16);
}

.error {
  @extend .statusBar;

  @include main-font;

  width: 100%;
  background-color: map-get($colors, red);
}

.modelCodeSelect {
  &__control {
    height: 48px;
    font-family: $ff-lato;
    font-size: map-get($sizes, regular);
    line-height: 20px;
    border: 1px solid map-get($colors, darker-gray);
    border-radius: 6px;
    color: map-get($colors, main-black);
    background-color: map-get($colors, white);
  }

  &__indicator {
    transform: rotateZ(180deg);
  }

  &__menu {
    display: flex;
    height: 200px;
    padding: 0;
    font-family: $ff-lato;
    font-size: map-get($sizes, regular);
    line-height: 20px;
    border: 1px solid map-get($colors, darker-gray);
    border-radius: 10px;
    background-color: map-get($colors, white);
    box-shadow: 0 12px 30px 0 rgba(map-get($colors, black), 0.16);
    flex-direction: column;

    :global {
      .menuHidden & {
        display: none;
      }
    }

    &-list {
      padding: 0;

      :global {
        .addMenuItemSelect__option {
          display: flex;
          height: auto;
          min-height: 44px;
          font-family: $ff-lato;
          font-size: map-get($sizes, regular);
          line-height: 20px;
          border: none;
          color: map-get($colors, main-black);
          align-items: center;

          &--is-selected {
            background-color: rgba(map-get($colors, light-blue), 0.1);
          }

          &--is-focused {
            background-color: rgba(map-get($colors, light-blue), 0.05);
          }
        }
      }
    }
  }
}
