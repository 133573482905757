@import '~styles/index.scss';

.panelContainer {
  :global {
    .conciergePanelBody {
      padding: 0;
    }
  }
}

.blockContent {
  display: flex;
  padding: 15px 0 10px 0;
  justify-content: space-between;

  &:first-child {
    padding-top: 25px;
  }

  &:last-child {
    padding-bottom: 0;
  }
}

.creationInfo {
  flex-direction: column;

  &Date {
    color: map-get($colors, font-gray);
  }
}

.repairOrderDetails {
  margin: 0 25px;

  @include secondary-font(map-get($sizes, small));
}

.repairOrderLabel {
  margin: 0 2px;
  color: map-get($colors, font-gray);
}

.repairOrderText {
  font-family: $ff-lato;
  font-size: $fs-regular;
  color: map-get($colors, main-black);
}

.repairOrderTag {
  display: flex;
  height: 35px;
  align-items: center;

  span {
    margin: 0 2px;
  }

  :global {
    .conciergeInputWrapper {
      display: inline-block;
      width: 150px;
      margin: 0 5px;
    }
  }
}

.repairOrderInput {
  height: 25px;
  margin: 0 2px;
}

.datePicker {
  display: flex;
  font-family: $ff-lato;
  font-size: $fs-regular;
  justify-content: center;

  :global {
    .rmdp-day.rmdp-selected span:not(.highlight) {
      background-color: map-get($colors, warning-red);
    }

    .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
      background-color: map-get($colors, darkest-gray);
    }

    .rmdp-week-day {
      color: map-get($colors, font-gray);
    }

    .rmdp-day.rmdp-today span {
      color: map-get($colors, main-black);
      background-color: map-get($colors, white);
    }

    .rmdp-day,
    .rmdp-week-day {
      width: 38px;
    }
  }
}

.vehicleDetails {
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0 25px;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.saveButton {
  display: flex;
  height: auto;
  color: map-get($colors, light-blue);
  align-items: center;
}

.buttonsContainer {
  display: flex;

  hr {
    height: 13px;
    border: 1px solid map-get($colors, font-gray);
  }
}

.halfPart {
  width: 50%;
}

.label {
  @include main-font(map-get($sizes, smaller));

  margin-bottom: 10px;
  color: map-get($colors, font-gray);
}

.value {
  @include main-font;

  text-transform: capitalize;

  &NotTransformed {
    text-transform: none;
  }
}
