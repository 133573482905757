$colors: (
  black: #000,
  main-black: #0a0c16,
  shadow-gray: #1b2b29,
  blueish-gray: #34364a,
  dark-gray: #2e3042,
  silver-gray: #868e9e,
  bluey-gray: #142d5a,
  darkest-gray: #9aa3b4,
  gray: #9ba4b5,
  font-gray: #a4acbc,
  darker-gray: #dedee0,
  light-gray: #f9fafc,
  background-gray: #f7f9fb,
  smoke-white: #f7f9fb,
  white: #fff,
  darker-white: #f9fafc,
  white-gray: #eaecef,
  blue: #3058c3,
  light-blue: #0bcaf9,
  pink: #fe3e7f,
  warning-red: #fe3e7f,
  green: #36af5e,
  green2: #37b05f,
  green3: #34a85b,
  green030: rgba(#37b05f, 0.3),
  red: #fa305e,
  primary: #2b5fc0,
  primary-active: #85a2db,
  primary-gradient: linear-gradient(90deg, #5e38e7 0%, #2b5fc0 100%),
  button-gradient: linear-gradient(45deg, #5430d8 0%, #2b5fc0 100%),
  mint: #0bcaf9,
  mint005: rgba(11, 202, 249, 0.05),
  pale-blue: #e5fafe,
  pale-green: #eaf7ef,
  pale-yellow: #ffffe8,
  pale-orange: #fee5008e,
  warning: #fe9339,
);

$color-white: #fff;
$color-smoke-white: #f7f9fb;
$color-darker-white: #f9fafc;
$color-pale-blue: #f3fcff;
$color-pale-green: #eaf7ef;
$color-amber: rgba(#ffc000, 0.7);

$color-primary-gradient: linear-gradient(90deg, #5e38e7 0%, #2b5fc0 100%);
$color-secondary-gradient: linear-gradient(45deg, #5430d8 0%, #2b5fc0 100%);
$color-dark-gradient: linear-gradient(90deg, #21222f 0%, #151620 100%);
$color-gray-gradient: linear-gradient(180deg, #fff 0%, #dedee0 150%);
$color-pink-gradient: linear-gradient(0deg, #fe3e7f0d, #fe3e7f0d), #fff;
$color-pink-gradient-secondary: linear-gradient(0deg, #fe3e7f1f, #fe3e7f21), #fff;
$color-light-blue-gradient: linear-gradient(0deg, #0bcaf90d, #0bcaf90d), #fff;
$color-light-blue-gradient-secondary: linear-gradient(0deg, #0bcaf924, #0bcaf930), #fff;

$color-primary-purple: #6b46ff;
$color-secondary-mint: #0bcaf9;
$color-secondary-mint-05: #0bcaf90d;
$color-secondary-mint-50: #0bcaf980;

$color-warning-red: #fe3e7f;
$color-blue: #0b6ef9;
$color-dark-blue: #121422;
$color-green: #1eb53a;
$color-green2: #37b05f;
$color-aqua: #0bcaf9;

$color-light-gray: #dedee0;
$color-gray: #9ba4b5;
$color-gray-04: rgba(#9ba4b5, 0.4);
$color-silver-gray: #868e9e;
$color-matte-gray: #34364a;
$color-dark-gray: #2e3042;
$color-black: #0a0c16;

$color-black-02: rgba(#0a0c16, 0.2);
$color-shadow-1: #1b2b29;
$color-shadow-2: #445154;
