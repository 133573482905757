@import '~styles/index.scss';

.conciergeImageInput {
  &Label {
    position: relative;
    display: flex;
    min-width: 144px;
    height: 72px;
    margin: 15px 0;
    align-items: center;

    &Image {
      display: flex;
      overflow: hidden;
      width: 100%;
      height: 100%;
      padding: 10px;
      border: $default-border;
      border-radius: $small-border-radius;
      background-color: map-get($colors, white);
      align-items: center;
      justify-content: center;
    }
  }

  &Rounded {
    width: 72px;
    height: 72px;
    border-radius: 50%;

    img {
      border-radius: 50%;
    }
  }

  &OutsideTrigger {
    padding: 22px 18px;
    padding-right: 0;

    p {
      font-size: map-get($sizes, smaller);
    }
  }

  &FileInput {
    @include absolute(top 0 left 0);
    @include sizing(100%);

    z-index: $z-index-2;
    opacity: 0;
    cursor: pointer;

    &:disabled {
      cursor: auto;
    }
  }

  &Image {
    display: block;
    width: auto;
    height: inherit;
    margin: 0 auto;
  }

  &DeleteButton {
    display: flex;
    margin-left: auto;
    font-family: $ff-ibm;
    font-size: map-get($sizes, slightly-bigger);
    border-radius: 6px;
    color: map-get($colors, main-black);
    background: none;
    background: transparent;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: rgba(map-get($colors, light-blue), 0.05);
    }
  }

  &NoImageText {
    @include secondary-font(map-get($sizes, bigger));

    line-height: map-get($sizes, slightly-bigger);
    text-align: center;
    color: map-get($colors, font-gray);

    .conciergeImageInputRounded & {
      font-size: map-get($sizes, smaller);
      font-style: $fs-normal;
      font-weight: $fw-normal;
    }
  }

  &UploadText {
    @extend .conciergeImageInputNoImageText;

    color: map-get($colors, light-blue);
  }

  &HoverView {
    @include absolute(top 0);
    @include sizing(100%);

    z-index: $z-index-1;
    display: flex;
    background-color: map-get($colors, white);
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
