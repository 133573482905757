@import '~styles/index.scss';

.container {
  :global {
    .conciergeInputWrapper {
      width: 100%;
    }

    .conciergePanelBody {
      padding: 0;
    }
  }
}

.section {
  display: flex;
  padding: 15px;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;

  &Editing {
    flex-flow: column nowrap;
    align-items: flex-end;
  }
}

.blockText {
  white-space: pre-line;
}

.editButton {
  padding: 0 5px 0 0;
}

.saveButton {
  font-size: map-get($sizes, slightly-smaller);
  color: map-get($colors, light-blue);
  cursor: pointer;
}
