@import '~styles/index.scss';

.addFallbackOpCode {
  &Select {
    &__control {
      height: 48px;
      font-family: $ff-lato;
      font-size: map-get($sizes, regular);

      &--is-disabled {
        background-color: #dedee0 !important;
      }
    }

    &__placeholder {
      font-family: $ff-lato;
      font-size: map-get($sizes, regular);
    }

    &__menu {
      overflow: hidden;
      max-height: 150px;
      font-family: $ff-lato;
      font-size: map-get($sizes, regular);
    }

    &__menu-list {
      height: 150px;
    }
  }
}

.switch {
  &Label {
    font-family: $ff-lato;
    font-size: map-get($sizes, slightly-smaller);
    line-height: 18px;
    color: map-get($colors, main-black);
  }

  &InputWrapper {
    display: flex;
    margin-top: 20px;
    align-items: center;

    .integrationActivitySwitch {
      margin-left: 15px;
    }
  }
}
