@import '~styles/index.scss';

.menuItem {
  position: relative;

  &Red {
    border-color: $color-warning-red;
  }
}

.header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-family: $ff-lato;
  font-size: map-get($sizes, bigger);
}

.tooltip {
  font-family: $ff-lato;
  font-size: map-get($sizes, regular);
}

.actionsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.icon {
  margin-left: 10px;
}

.switch {
  display: flex;
  align-items: center;

  &Container {
    display: flex;
  }

  &Label {
    width: max-content;
    padding: 0 6px 0 16px;
    font-family: $ff-lato;
    font-size: map-get($sizes, smaller);
    line-height: 28px;
    color: map-get($colors, main-black);
  }
}

.actionsButton {
  display: flex;
  padding: 0 14px;
  border: none;
  background: transparent;
  align-items: center;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.popup {
  @include absolute(top 50px right 0);

  z-index: $z-index-1;
  display: flex;
  padding: 8px 0;
  border: 1px solid map-get($colors, darker-gray);
  border-radius: 10px;
  background-color: map-get($colors, white);
  box-shadow: 0 12px 30px 0 rgba(map-get($colors, black), 0.16);
  flex-direction: column;
}

.outsideOverlay {
  @include fixed(top 0 left 0);
  @include sizing(100vw 100vh);

  border: none;
  background: transparent;
}

.action {
  @include sizing(auto 44px);

  display: flex;
  padding-right: 16px;
  font-family: $ff-lato;
  font-size: map-get($sizes, regular);
  line-height: 20px;
  border: none;
  color: map-get($colors, main-black);
  background: none;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: rgba(map-get($colors, light-blue), 0.05);
  }

  img {
    margin: 0 8px;
  }

  &Delete {
    color: map-get($colors, pink);
  }
}

.delete {
  @extend .action;

  color: map-get($colors, pink);
}
