@import '~styles/index.scss';

.container {
  height: auto;
  min-height: 100vh;

  &Main {
    @include horizontally-centered;

    padding: 16px 0;
  }
}

.addButton {
  display: flex;
  justify-content: flex-end;
}

.confirmVehicle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.button {
  padding: 16px 32px;
  font-family: $ff-ibm;
  font-size: map-get($sizes, slightly-bigger);
  line-height: 16px;
  border: none;
  border-radius: 6px;
  color: map-get($colors, white);
  background: map-get($colors, button-gradient);
  box-shadow: 0 8px 15px 0 rgba(map-get($colors, black), 0.18);
  cursor: pointer;
}

.buttonGroup {
  display: flex;
  padding-top: 16px;

  button:not(:first-child) {
    margin-left: 16px;
  }
}

.buttonSection {
  margin: 25px 0 0 0;
}

.label {
  @include main-font(map-get($sizes, smaller));

  margin-bottom: 10px;
  color: map-get($colors, font-gray);
}

.panel {
  @include main-font(map-get($sizes, slightly-bigger));

  width: 100%;
  margin: 10px 0;
}

.resultsMsg {
  @include main-font(map-get($sizes, slightly-smaller));

  margin: 20px 0 10px;
  color: map-get($colors, font-gray);
}

.personal {
  display: flex;

  &Container {
    display: flex;
    width: 50%;
  }

  &Section {
    display: flex;
    margin: 5px 0;
    flex: 1;
    flex-direction: column;
  }
}

.vehicle {
  display: flex;

  &Container {
    @include sizing(50% 240px);

    display: flex;
    justify-content: space-between;
  }

  &SectionsContainer {
    width: 48.5%;
  }

  &Column {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  &Section {
    height: 40px;
    margin: 0 0 20px;
  }

  &Header {
    @include main-font(map-get($sizes, much-bigger), $fw-bold);

    display: flex;
    margin: 5px 0 10px 5px;
    align-items: center;
    justify-content: space-between;
  }

  &Image {
    @include sizing(250px 175px);

    margin-left: 5px;
  }

  &NoImage {
    @include sizing(250px 175px);

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .imageContainer {
    @include sizing(50% 240px);

    display: flex;
    flex-direction: column;
    justify-content: inherit;
  }

  .inactive {
    display: flex;
    padding-top: 5px;
    flex-direction: column;

    span {
      height: 20px;
      color: map-get($colors, warning-red);
    }
  }
}

.block {
  @include main-font(map-get($sizes, regular));

  margin: 15px 0 20px;

  :global {
    .conciergeBlockContent {
      padding: 15px 25px;
    }

    .conciergePanelBody {
      margin: 25px 21px;
    }

    .notification {
      box-shadow: none;
    }
  }

  &Inactive {
    :global {
      .conciergeBlockContent {
        background-color: map-get($colors, light-gray);
      }
    }
  }
}

.header {
  @include main-font(map-get($sizes, slightly-smaller), $fw-bold);
}

.loading {
  @include sizing(64px);

  margin-top: 32px;
}

.loadingPanel {
  @include main-font(map-get($sizes, bigger));

  width: 100%;
  text-align: center;
  color: map-get($colors, darkest-gray);
}

.uneditable {
  color: map-get($colors, darkest-gray);
}

.buttonsContainer {
  display: flex;

  hr {
    height: 10px;
    border: 1px solid map-get($colors, font-gray);
  }
}
