@import '~styles/index.scss';

.panel {
  :global {
    .conciergePanelBody {
      margin: 0 !important;
      padding: 0;
    }

    .conciergePanel {
      margin: 35px 0;
    }

    .conciergeBlock {
      margin: 0;
    }

    .conciergeBlockContent {
      border: none;
    }
  }
}

.total {
  padding: 15px;
  font-size: $fs-smaller;
  background-color: map-get($colors, darker-white);

  &Value {
    padding: 15px 15px;
    font-weight: $fw-bold;
  }
}

.info {
  display: flex;
  padding-bottom: 15px;
  background-color: map-get($colors, darker-white);
  flex-direction: column;
  justify-content: space-between;
}

.infoRow {
  display: flex;
  border-radius: 10px;
  background-color: map-get($colors, darker-white);
  flex-direction: row;
  justify-content: space-between;
}

.item {
  &Container {
    margin-top: 15px;
    padding: 0 15px;
  }

  &Label {
    color: map-get($colors, font-gray) !important;
  }
}

.button {
  padding: 15px;
}
