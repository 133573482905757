@import '~styles/index.scss';

.usersPanel {
  :global {
    .ReactTable {
      .rt-table {
        overflow: visible;
      }

      .rt-tbody {
        overflow: visible;
      }

      .rt-tbody .rt-td {
        padding-left: 25px;
      }
    }
  }
}

.button {
  display: flex;
  margin-top: 25px;
  justify-content: flex-end;
}
