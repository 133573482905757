@import '~styles/index.scss';

.recallService {
  display: flex;
  width: 100%;
  margin: 12px 0;
  padding: 15px;
  border: 1px solid $color-darker-white;
  border-radius: 10px;
  background-color: $color-white;
  box-shadow: 0 4px 10px 0 rgba($color-shadow-1, 0.08);
  flex-direction: row;
  cursor: pointer;

  &.highlighted {
    border: 1px solid $color-secondary-mint;
    background-color: rgba($color-secondary-mint, 0.05);
  }

  .headerContainer {
    display: flex;
    width: 100%;
    padding: 1px 6px;
    font-family: $ff-lato;
    text-align: left;
    flex-direction: column;
  }

  .header {
    display: flex;
    margin-bottom: 5px;
    align-items: flex-start;
    justify-content: space-between;
  }

  .label {
    line-height: 25px;
  }

  .selection {
    margin-left: 5px;
  }

  .selectedIcon,
  .unselectedIcon {
    @include sizing(32px);

    min-width: 32px;
    min-height: 32px;

    @include flex-centered;

    border: 1px solid map-get($colors, light-blue);
    border-radius: 50%;
  }

  .selectedIcon {
    background-color: map-get($colors, light-blue);
    box-shadow: 0 4px 15px 0 rgba(map-get($colors, light-blue), 0.4);

    img {
      height: 11px;
      margin: 1px 2px 0 0;
    }
  }

  .unselectedIcon {
    background-color: map-get($colors, white);
  }

  .nameContainer {
    display: flex;
    flex-direction: row;
  }

  .name {
    font-family: $ff-lato;
    font-size: $fs-regular;
    font-weight: $fw-bold;
    text-align: left;
    cursor: pointer;
  }

  .description {
    margin: 0 -18px 15px -18px;
    padding: 0 25px 0 18px;
    background: $color-darker-white;

    .content {
      padding: 10px 0;
      font-size: $fs-small;
    }
  }

  .data {
    margin-right: 10px;
    margin-bottom: 15px;
    line-height: 20px;
  }

  .icon {
    min-width: 22px;
    margin: 0 10px;
    vertical-align: text-bottom;
  }

  .dataKey {
    margin-right: 0.5em;
    font-size: $fs-small;
    color: $color-gray;
  }

  .dataValue {
    font-size: $fs-small;
    color: $color-black;
  }

  .price {
    font-size: $fs-small;
    font-weight: $fw-bold;
  }

  .tooltip {
    font-family: $ff-lato;
    font-size: map-get($sizes, smaller);
  }
}
