@import '~styles/index.scss';

.qrContainer {
  font-family: $ff-lato;
  background-color: map-get($colors, main-black);

  &Centering {
    @include horizontally-centered;

    display: flex;
    padding: 40px 0;
    color: $color-white;
    align-items: center;
    justify-content: center;
  }

  &Info {
    display: flex;
    height: 100px;
    padding-left: 40px;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-between;

    button {
      margin-top: 24px;
    }
  }
}

.qrCodeContainer {
  display: none;
}

.body {
  display: flex;
  margin-top: 50px;
  justify-content: center;
}

.titles {
  display: flex;
  margin-bottom: 50px;
  margin-left: 50px;
  flex-direction: column;

  span {
    @include main-font(map-get($sizes, large));

    line-height: 30px;
  }
}

.bottom {
  @include main-font(map-get($sizes, large));

  width: 100%;
  margin-top: 50px;
  padding-bottom: 50px;
  text-align: center;
  border-bottom: 3px solid map-get($colors, black);
}

.name {
  @include main-font(map-get($sizes, very-large), $fw-bold);

  width: 100%;
  padding-bottom: 50px;
  text-align: center;
  border-bottom: 3px solid map-get($colors, black);
}

.service {
  @include sizing(90px);
}

.logo {
  @include sizing(150px auto);

  padding-top: 50px;
}

@media (orientation: portrait) {
  body > .qrCodeContainer {
    display: flex;
    height: 1100px;
    padding: 60px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media (orientation: landscape) {
  body > .qrCodeContainer {
    display: flex;
    height: 790px;
    padding: 60px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .name {
    padding: 20px;
  }

  .body {
    margin-top: 20px;
  }

  .titles {
    margin-bottom: 20px;
    margin-left: 20px;
  }

  .bottom {
    margin-top: 20px;
    padding-bottom: 20px;
  }

  .logo {
    padding-top: 20px;
  }
}
