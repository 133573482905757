@import '~styles/index.scss';

.areasMarkersContainer {
  position: absolute;
  display: flex;

  &-90 {
    top: -40px;
    left: -40px;
    flex-direction: column;

    &.segmentsFour {
      flex-direction: column-reverse;
    }

    &.segmentsSix {
      top: -20px;
    }
  }

  &-180 {
    top: -40px;
    left: -40px;
    flex-direction: row-reverse;

    &.segmentsSix {
      flex-direction: row;
    }
  }

  &-270 {
    top: -40px;
    flex-direction: column-reverse;

    &.segmentsFour {
      flex-direction: column;
    }

    &.segmentsSix {
      top: -20px;
    }
  }

  &-360 {
    left: -41px;
  }

  .leftMarker {
    display: flex;
    width: 40px;
    height: 40px;
    font-family: $ff-lato;
    font-size: $fs-regular-bigger;
    font-style: normal;
    font-weight: $fs-regular-bigger;
    line-height: 22px;
    color: $color-white;
    background-color: #6b46ff;
    align-items: center;
    justify-content: center;
  }

  .rightMarker {
    display: flex;
    width: 40px;
    height: 40px;
    font-family: $ff-lato;
    font-size: $fs-regular-bigger;
    font-style: normal;
    font-weight: $fs-regular-bigger;
    line-height: 22px;
    background-color: #0bcaf9;
    align-items: center;
    justify-content: center;
  }
}
