@import '~styles/index.scss';

.header {
  display: flex;
  width: 100%;
  padding-bottom: 16px;

  .subtitle {
    font-family: $ff-lato;
    font-size: map-get($sizes, regular);
    line-height: 18px;
    color: map-get($colors, font-gray);
  }
}

.body {
  .question {
    font-family: $ff-lato;
    font-size: map-get($sizes, regular);

    .bool {
      display: flex;
      margin: 8px 0;
      border: 1px solid rgb(222, 222, 224);
      border-radius: 8px;
    }

    .string {
      margin: 8px 0;

      input {
        display: block;
        width: 100%;
        height: 44px;
        margin: 3px 0;
        padding-left: 16px;
        font-family: $ff-lato;
        font-size: $fs-slightly-bigger;
        border: 1px solid #dedee0;
        border-radius: 6px;
      }
    }
  }
}

.buttonsContainer {
  display: flex;
  margin-top: 15px;
  flex-direction: row;
}

.saveButton {
  margin-right: 15px;
}

.errorContainer {
  display: flex;
  margin-bottom: 8px;
  padding: 12px;
  border: 1px solid rgba(254, 62, 127, 0.25);
  border-radius: 8px;
  background: rgba(254, 62, 127, 0.13);
  flex-direction: row;
  align-items: center;
  gap: 12px;

  .icon {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 2px;
    border-radius: 100px;
    background-color: map-get($colors, warning-red);
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .error {
    font-family: $ff-lato;
    font-size: map-get($sizes, regular);
    line-height: 18px;
    color: map-get($colors, warning-red);
  }
}

.loading {
  @include main-font(map-get($sizes, bigger));

  width: 100%;
  padding: 10px;
  text-align: center;
  color: map-get($colors, darkest-gray);
}
