@import '~styles/index.scss';

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.loader {
  @include main-font(map-get($sizes, bigger));

  display: block;
  width: 100%;
  text-align: center;
  color: map-get($colors, darkest-gray);
}

.label {
  @include main-font;

  display: block;
  width: 100%;
  padding: 8px 0;
  font-size: map-get($sizes, smaller);
  line-height: 18px;
  color: map-get($colors, main-black);

  &HalfWidth {
    display: inline-block;
    width: 48%;
  }
}

.select {
  @include main-font;

  display: block;
  width: 48%;
  padding: 8px 0;
  font-size: map-get($sizes, smaller);
  line-height: 18px;
  color: map-get($colors, main-black);

  :global(.StyledSelect__menu) {
    height: 200px;
  }
}

.input {
  @include sizing(100% 48px);
  @include main-font;

  width: 100%;
  padding: 14px 16px;
  font-size: map-get($sizes, regular);
  line-height: 20px;
  border: 1px solid map-get($colors, darker-gray);
  border-radius: 6px;
  color: map-get($colors, main-black);
  background-color: map-get($colors, white);

  span {
    margin-bottom: 4px;
  }
}
