@import '~styles/index.scss';

.conciergeSettingsPageTeamTagsPanel {
  .conciergePanelBody {
    padding: 0;
  }

  .ReactTable {
    border: none;
    border-radius: 0;
    border-bottom: $default-border;

    .rt-table {
      overflow: visible;
    }

    .rt-tbody {
      overflow: visible;

      .rt-tr {
        align-items: center;
      }

      .rt-td {
        padding-top: 12px;
      }

      .rt-td.draggable,
      .rt-th.draggable {
        cursor: move;
        cursor: grab;

        img {
          width: 16px;
        }
      }

      .rt-th.draggable {
        opacity: 0;
      }
    }

    .rt-noData {
      top: 74%;
    }

    .conciergeTableInputCell {
      flex: 1;

      .conciergeInputWrapper {
        margin: 0;
      }
    }
  }

  &ButtonSection {
    display: flex;
    margin: 20px;
    justify-content: flex-end;
  }

  &Button-add {
    @include secondary-font(map-get($sizes, slightly-bigger));

    padding: 16px 32px;
    line-height: 16px;
    border: none;
    border-radius: 6px;
    color: map-get($colors, white);
    background-color: map-get($colors, blueish-gray);

    &:hover {
      box-shadow: $default-box-shadow;
    }
  }

  .conciergeSettingsAddUserModal {
    top: calc(50% - 200.5px);
    height: auto;

    &Footer {
      padding-bottom: 20px;
    }
  }
}
