@import '~styles/index.scss';

.conciergeApp {
  .conciergeRemoteServicePage {
    height: calc(100% - #{$header-height});
    min-height: calc(100vh - #{$header-height});

    &Navigation {
      height: 48px;
    }

    &Main {
      display: flex;
      overflow: hidden;
      height: calc(100% - #{$header-height} - 128px);
      min-height: calc(100vh - #{$header-height} - 128px);
      max-height: calc(100vh - #{$header-height} - 128px);
      flex-direction: column;
    }
  }
}

.conciergeApp.withReports {
  .conciergeRemoteServicePage {
    height: calc(100% - #{$header-height-with-reports});
    min-height: calc(100vh - #{$header-height-with-reports});

    &Navigation {
      height: 48px;
    }

    &Main {
      display: flex;
      overflow: hidden;
      height: calc(100% - #{$header-height-with-reports} - 128px);
      min-height: calc(100vh - #{$header-height-with-reports} - 128px);
      max-height: calc(100vh - #{$header-height-with-reports} - 128px);
      flex-direction: column;
    }
  }
}

.conciergeRemoteServicePage {
  &Navigation {
    @include horizontally-centered;
  }

  &Map {
    flex: 1;
  }

  &Details {
    display: flex;
    overflow: hidden;
    width: 700px;
    padding: 24px 32px;
    background-color: white;
    flex-direction: column;

    &Collapsed {
      width: 36px;
      padding: 10px;
    }

    &CollaspeButtonContainer button {
      padding: 0;
      padding-bottom: 27px;
    }

    &LeftContainer {
      display: flex;
      flex: 1;
      justify-content: space-between;

      &Select {
        @include main-font;

        width: 150px;

        div[class*="-control"] {
          height: 40px;
        }
      }

      &Resync {
        height: 40px;
        margin-right: 12px;
        border-radius: 4px;
        color: $color-black;
      }
    }

    &Filters {
      display: flex;

      .conciergeButton:not(:first-child) {
        margin-left: 12px;
      }

      .vl {
        height: 40px;
        border: 0.5px solid rgba(128, 128, 128, 0.2);
      }

      .filterActive {
        color: map-get($colors, light-blue);
        background-color: rgba(11, 202, 249, 0.05);
        filter: invert(60%) sepia(65%) saturate(1044%) hue-rotate(149deg) brightness(99%) contrast(98%);
      }
    }

    &Calendar {
      display: flex;
      padding: 12px;
      border-top: 0.5px solid rgba(128, 128, 128, 0.2);
      border-bottom: 0.5px solid rgba(128, 128, 128, 0.2);
      justify-content: center;
    }

    &FilterContainer {
      display: flex;
      align-items: end;
      justify-content: space-between;
    }
  }

  &Container {
    display: flex;
    overflow: auto;
    flex: 1;
    justify-content: space-between;
  }

  .flexSpaceBetween {
    display: flex;
    justify-content: space-between;
  }

  .marginBottom {
    margin-bottom: 12px;
  }

  .settingsMainLabel {
    margin-bottom: 24px;
    padding-bottom: 24px;
    font-family: $ff-lato;
    font-size: $fs-big;
    font-style: normal;
    font-weight: $fs-regular-bigger;
    line-height: 28px;
    border-bottom: 0.5px solid rgba(128, 128, 128, 0.2);
  }

  .settingsServiceZone {
    display: flex;
    padding: 24px 0;
    border-top: 0.5px solid rgba(128, 128, 128, 0.2);
    border-bottom: 0.5px solid rgba(128, 128, 128, 0.2);
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .settingsServiceLabel {
    font-family: $ff-lato;
    font-size: $fs-small;
    font-style: normal;
    font-weight: $fs-regular-bigger;
    line-height: 18px;
  }

  .settingsServiceSelector {
    min-width: 318px;
    flex: 1;

    &Container {
      display: flex;
      flex-wrap: wrap;
    }

    &Label {
      margin: 12px 0;
      font-family: $ff-lato;
      font-size: $fs-small;
      font-style: normal;
      font-weight: $fs-regular-bigger;
      line-height: 18px;
    }

    &BtnContainer {
      display: flex;

      button:not(:first-child) {
        margin-left: 8px;
      }
    }
  }

  .settingsServiceConfirmation {
    display: flex;
    margin-top: 24px;
    padding: 24px 0;
    border-top: 0.5px solid rgba(128, 128, 128, 0.2);
    border-bottom: 0.5px solid rgba(128, 128, 128, 0.2);
    align-items: center;
    justify-content: center;

    button:not(:first-child) {
      margin-left: 16px;
    }
  }
}

.jobCards {
  overflow: auto;
}

.cardsLoading {
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: center;
}

img[src$="#custom_marker"] {
  border: 6px solid #900 !important;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 6px rgba(254, 62, 127, 0.15);
  -moz-box-shadow: 0 0 0 6px rgba(254, 62, 127, 0.15);
  box-shadow: 0 0 0 6px rgba(254, 62, 127, 0.15);
}

.infoSection {
  overflow: auto;
}
