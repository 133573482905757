$ff-lato: 'Lato';
$ff-ibm: 'IBM Plex Sans Condensed';

$sizes: (
  tiny: 10px,
  much-smaller: 11px,
  smaller: 13px,
  slightly-smaller: 14px,
  regular: 15px,
  slightly-bigger: 16px,
  bigger: 17px,
  much-bigger: 18px,
  big: 20px,
  very-big: 22px,
  large: 26px,
  very-large: 40px,
  maxi: 55px,
  huge: 72px,
);

$fw-bold: bold;
$fw-normal: normal;

$fs-normal: normal;
$fs-tiny: 10px;
$fs-smaller: 13px;
$fs-small: 14px;
$fs-regular: 15px;
$fs-slightly-bigger: 16px;
$fs-regular-bigger: 16px;
$fs-bigger: 17px;
$fs-much-bigger: 18px;
$fs-big: 20px;
$fs-very-big: 22px;
$fs-large: 28px;
$fs-very-large: 40px;
$fs-maxi: 55px;
$fs-huge: 72px;

$color-transparent: rgba(0, 0, 0, 0);
