@import "~styles/index.scss";
@import 'react-dual-listbox/lib/react-dual-listbox.css';

.dealershipSettings {
  &Wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  &Block {
    .conciergeBlockContent {
      background-color: map-get($colors, light-gray);
    }

    &Input {
      &Error {
        @include main-font(map-get($sizes, smaller));

        margin-top: 5px;
        color: map-get($colors, pink);
      }
    }
  }

  &SwitchWrapper {
    display: flex;
    padding-top: 24px;
  }

  &SwitchContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &SwitchContainer:not(:last-child) {
    margin-bottom: 20px;
  }

  &Switch {
    width: 90px;
    margin-left: 15px;
  }

  &EditButton {
    margin-left: auto;
  }

  &Label {
    @include main-font;

    display: block;
    padding: 8px 0;
    font-size: map-get($sizes, smaller);
    line-height: 18px;
    color: map-get($colors, main-black);
  }

  &Select {
    @include main-font;

    color: map-get($colors, main-black);

    .StyledSelect__menu {
      height: 200px;
    }
  }
}

.settingsAccordion {
  margin-bottom: 20px;
}
