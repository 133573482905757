@import '~styles/index.scss';

.header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  .label {
    font-family: $ff-lato;
    font-size: $fs-bigger;
    font-weight: $fw-bold;
  }
}

.container {
  display: grid;
  width: 60%;
  margin-left: 30px;
  padding-top: 15px;
  grid-template-columns: 1fr repeat(3, 3fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  font-family: $ff-lato;

  :global {
    .conciergeInputWrapper {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  .item {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;

    &Input {
      margin-bottom: 0;
    }
  }

  .tableContent {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;

    span {
      font-size: map-get($sizes, slightly-smaller);
      text-align: right;
      color: map-get($colors, font-gray);
    }
  }
}

.tooltip {
  @include main-font(map-get($sizes, smaller));

  max-width: 250px;
}

.block {
  margin: 0;
}

.navigation {
  display: flex;
  padding-bottom: 15px;
  border-bottom: 1px solid map-get($colors, darker-gray);
  flex-direction: column;
  align-items: start;
  justify-content: space-between;

  .inputsContainer {
    display: flex;
    margin-top: 16px;
    margin-left: 30px;
  }

  :global {
    .conciergeInputWrapper {
      width: 170px;
      margin: 0 15px 0 0;
    }
  }
}

.select {
  @include main-font(map-get($sizes, regular));

  width: 170px;
  margin: 3px 15px 0 0;

  :global {
    div[class*='control'] {
      height: 44px;
      margin: 3px 0;
      font-family: $ff-lato;
      font-size: $fs-regular-bigger;
      font-weight: $fs-normal;
      border: 1px solid #dedee0;
    }
  }
}

.label {
  @include main-font(map-get($sizes, smaller));

  display: flex;
  flex-direction: column;

  &Title {
    display: flex;
    align-items: center;
  }

  &Subtitle {
    color: map-get($colors, font-gray);
  }
}

.invalid {
  border: 1px solid red;
}

.caption {
  display: flex;
  font-size: $fs-smaller;
  align-items: center;

  &Icon {
    margin-left: 5px;
  }

  &Subtitle {
    margin-top: 3px;
    font-size: $fs-smaller;
    color: map-get($colors, gray);
  }
}
