@import '~styles/index.scss';

.jobCard {
  @include main-font;

  margin-top: 16px;
  padding: 16px;
  border: 1px solid #dedee0;
  border-radius: 4px;
  cursor: pointer;

  &Active {
    padding: 15px;
    border: 2px solid #0bcaf9;
  }

  &Disabled {
    cursor: not-allowed;
  }

  &Header {
    display: flex;
    justify-content: space-between;

    &Type {
      display: flex;
      height: 30px;
      padding: 6px 8px;
      border-radius: 4px;
      color: $color-gray;
      background-color: rgba(155, 164, 181, 0.08);
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 4px;
    }

    &Status {
      display: flex;
      height: 30px;
      padding: 6px 8px;
      border-radius: 4px;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 4px;
    }

    .driverAssigned {
      color: $color-gray;
      background-color: rgba(155, 164, 181, 0.08);

      img {
        @include sizing(25px);
      }
    }

    .enRoutToCustomer {
      color: $color-aqua;
      background-color: rgba(11, 202, 249, 0.08);

      img {
        filter: invert(66%) sepia(32%) saturate(3374%) hue-rotate(149deg) brightness(100%) contrast(96%);
      }
    }

    .arrivedToCustomer {
      color: $color-warning-red;
      background-color: rgba(254, 62, 127, 0.08);

      img {
        filter: invert(40%) sepia(27%) saturate(4784%) hue-rotate(316deg) brightness(99%) contrast(104%);
      }
    }

    .enRouteToDealership {
      color: $color-primary-purple;
      background-color: rgba(107, 70, 255, 0.08);

      img {
        filter: invert(44%) sepia(100%) saturate(5596%) hue-rotate(243deg) brightness(100%) contrast(103%);
      }
    }

    .arrivedToDealership {
      color: $color-gray;
      background-color: rgba(155, 164, 181, 0.08);

      img {
        filter: invert(75%) sepia(17%) saturate(270%) hue-rotate(181deg) brightness(87%) contrast(81%);
      }
    }

    &RO {
      margin-top: 8px;
      font-family: $ff-lato;
      font-size: $fs-regular-bigger;
      font-style: normal;
      font-weight: $fw-bold;
      line-height: 22px;
    }

    &CarImage {
      margin-left: 16px;

      img {
        height: 40px;
      }
    }
  }

  &AppointmentInfo {
    display: flex;
    margin-top: 8px;

    p:not(:last-of-type) {
      width: 120px;
    }

    p {
      display: flex;
      font-family: $ff-lato;
      font-size: $fs-small;
      font-style: normal;
      font-weight: $fw-normal;
      line-height: 18px;
      align-items: center;
    }

    .vl {
      height: auto;
      min-height: 18px;
      margin: 0 12px;
      border: 0.5px solid rgba(128, 128, 128, 0.2);
    }
  }

  &DriversFooter {
    display: flex;
    margin-top: 8px;
    align-items: center;
    justify-content: space-between;

    &Info {
      display: flex;
      align-items: center;

      .driverImg {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }

      &Driver {
        margin-right: 24px;
        margin-left: 8px;

        .title {
          font-family: $ff-lato;
          font-size: $fs-smaller;
          font-style: normal;
          font-weight: $fw-normal;
          line-height: 16px;
          color: $color-gray;
        }

        .name {
          font-family: $ff-lato;
          font-size: $fs-small;
          font-style: normal;
          font-weight: $fw-bold;
          line-height: 18px;
        }
      }

      &Additional {
        margin-right: 24px;

        .dealership {
          font-family: $ff-lato;
          font-size: $fs-smaller;
          font-style: normal;
          font-weight: $fw-normal;
          line-height: 16px;
          color: $color-gray;
        }

        .time {
          font-family: $ff-lato;
          font-size: $fs-small;
          font-style: normal;
          font-weight: $fw-normal;
          line-height: 18px;
        }
      }
    }

    .conciergeButton {
      padding: 8px 16px;
      font-family: $ff-lato;
      font-size: $fs-regular-bigger;
      font-style: normal;
      font-weight: $fw-bold;
      line-height: 22px;
      border: 1px solid #dedee0;
    }
  }
}
