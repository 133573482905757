@import '~styles/index.scss';

.vacationsModal {
  font-family: $ff-lato;
  font-size: $fs-regular;
  font-weight: $fs-normal;

  :global {
    .conciergeAlert {
      margin-bottom: 0;
    }

    .conciergePanel {
      border-radius: 5px;
    }

    .conciergePanel:first-child {
      margin: 0 !important;
    }
  }
}

.panel {
  :global {
    .conciergePanelBody {
      margin: 24px;
    }
  }
}

.splitTime {
  color: $color-aqua;
}

.periodFilter {
  @include main-font;

  display: flex;

  :global {
    .conciergePanelBody {
      padding: 24px !important;
    }

    .conciergePanel {
      margin: 0 !important;
    }

    .conciergeDatepickerCalendarContainer {
      position: absolute;

      .conciergePanelBody {
        margin: 0;
        padding: 16px !important;
      }
    }
  }
}

.error {
  margin-bottom: 16px;
}

.infoBlock {
  display: flex;
  padding-top: 4px;
  justify-content: center;

  p {
    color: map-get($colors, font-gray);
  }
}
