@import '~styles/index.scss';

.container {
  margin-bottom: 25px;

  :global {
    .conciergeBlockTitle {
      text-transform: capitalize;
    }

    .conciergeInputWrapper {
      width: 50%;
      padding-left: 40px;
    }
  }
}

.title {
  @include main-font(map-get($sizes, regular));

  display: flex;
  height: 24px;
  justify-content: space-between;
}

.block {
  padding: 15px;
}

.switch {
  display: flex;
  margin-bottom: 20px;
  align-items: center;

  .label {
    @include main-font(map-get($sizes, smaller));

    margin-right: 10px;
  }
}

.accordion {
  margin-bottom: 20px;
}
