@import '~styles/index.scss';

.packageItem {
  display: flex;
  margin-bottom: 16px;
  padding: 24px;
  border: 1px solid map-get($colors, darker-gray);
  border-radius: 6px;
  background-color: map-get($colors, white);
  flex-wrap: wrap;
}

.input {
  display: flex;
  min-width: 100%;
  margin: 0 24px 20px 0;
  flex-direction: column;
}

.label {
  margin-bottom: 2px;
  font-family: $ff-lato;
  font-size: map-get($sizes, smaller);
  line-height: 18px;
  color: map-get($colors, main-black);
}

.select {
  :global(.select) {
    &__control {
      min-height: 38px;
      margin: 3px 0;
      font-family: $ff-lato;
      font-size: $fs-regular;
      line-height: 20px;
      border: 1px solid #dedee0;
      border-radius: 6px;
      color: $color-black;
      background-color: #fff;

      &--is-disabled {
        background-color: #e9ecef !important;
      }
    }

    &__menu {
      display: flex;
      height: 150px;
      padding: 8px 0;
      font-family: $ff-lato;
      font-size: map-get($sizes, regular);
      line-height: 20px;
      border: 1px solid map-get($colors, darker-gray);
      border-radius: 10px;
      background-color: map-get($colors, white);
      box-shadow: 0 12px 30px 0 rgba(map-get($colors, black), 0.16);
      flex-direction: column;

      .menuHidden & {
        display: none;
      }

      &-list {
        padding: 0;

        .select__option {
          display: flex;
          height: auto;
          min-height: 44px;
          font-family: $ff-lato;
          font-size: map-get($sizes, regular);
          line-height: 20px;
          border: none;
          color: map-get($colors, main-black);
          align-items: center;

          &--is-selected {
            background-color: rgba(map-get($colors, light-blue), 0.1);
          }

          &--is-focused {
            background-color: rgba(map-get($colors, light-blue), 0.05);
          }
        }
      }
    }
  }
}
