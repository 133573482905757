@import '~styles/index.scss';

.carousel {
  display: flex;
  min-height: 50px;
  flex-direction: column;
  justify-content: center;

  .spinner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    background-color: rgba(249, 250, 252, 0.6);
    align-items: center;
    justify-content: center;
  }
}

.loading {
  @include main-font(map-get($sizes, bigger));

  width: 100%;
  text-align: center;
  color: map-get($colors, darkest-gray);
}

.submit {
  display: flex;
  margin: 20px 0 0;
  justify-content: flex-end;
}
