@import '~styles/index.scss';

.conciergePageHeader {
  height: $page-header-height;
  border-bottom: 1px solid map-get($colors, darker-gray);
  background-color: map-get($colors, white);
  box-shadow: $default-box-shadow;

  &Centering {
    @include horizontally-centered;

    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
  }

  &Title {
    @include secondary-font;

    & > h2 {
      font-weight: $fw-normal;
    }
  }
}
