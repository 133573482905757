@import "~styles/index.scss";

.activity {
  display: flex;
  width: 48%;
  margin-bottom: 20px;
  align-items: center;
}

.switch {
  margin-left: 10px;
}

.label {
  font-family: $ff-lato;
  font-size: $fs-smaller;
}
