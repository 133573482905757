@import '~styles/index.scss';

.servicesToSelect {
  overflow: auto;
  max-height: 1000px;
  margin-top: 16px;
  padding: 0 10px;
}

.service {
  width: 100%;
  height: auto;
  min-height: 65px;
  margin: 12px 0;
  padding: 15px 15px;
  border: 1px solid map-get($colors, light-gray);
  border-radius: 10px;
  background-color: map-get($colors, white);
  box-shadow: 0 4px 10px 0 rgba(map-get($colors, shadow-gray), 0.08);
  cursor: pointer;

  .header {
    display: flex;
    width: 100%;
    background: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    cursor: pointer;
  }

  .headerContainer {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;

    .info {
      display: flex;
      flex-direction: row;
    }

    .label {
      text-align: left;
    }

    .caption {
      font-family: $ff-lato;
      font-size: map-get($sizes, regular);
      font-weight: $fw-bold;
      line-height: 25px;
      text-align: left;
      color: map-get($colors, black);

      &DefaultService {
        padding-left: 8px;
        color: map-get($colors, gray);
      }
    }
  }

  .price {
    margin-top: 8px;
    font-family: $ff-lato;
    font-size: map-get($sizes, regular);
    color: map-get($colors, gray);
  }

  &.selected {
    border: 1px solid map-get($colors, light-blue);
    background-color: rgba(map-get($colors, light-blue), 0.05);
    box-shadow: 0 4px 10px 0 rgba(map-get($colors, shadow-gray), 0.08);
  }

  &.disabled {
    background-color: rgba(map-get($colors, darkest-gray), 0.05);
    cursor: initial;

    .unselectedIcon {
      background-color: rgba(map-get($colors, darkest-gray), 0.05);
    }
  }

  &:focus {
    outline: none;
  }
}

.dropdown {
  display: flex;
  margin: 10px -15px -15px -15px;
  padding: 1em;
  padding-right: 20px;
  font-family: $ff-lato;
  font-size: map-get($sizes, smaller);
  line-height: 18px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  color: $color-silver-gray;
  background: $color-darker-white;
  justify-content: space-between;

  &Icon {
    height: 16px;
    margin-right: 10px;
  }
}

.included {
  display: flex;
  margin-top: 10px;
  font-family: $ff-lato;
  font-size: map-get($sizes, smaller);
  flex-direction: column;

  &Title {
    margin-top: 15px;
    color: $color-silver-gray;
  }

  .premiumItem {
    margin-top: 10px;
    font-family: $ff-lato;
    font-size: $fs-smaller;

    span {
      padding: 1px 3px;
      border-radius: 5px;
      color: $color-smoke-white;
      background: $color-green2;
    }
  }
}

.selectedIcon,
.unselectedIcon {
  @include sizing(32px);

  min-width: 32px;
  min-height: 32px;

  @include flex-centered;

  border: 1px solid map-get($colors, light-blue);
  border-radius: 50%;

  span {
    height: 24px;
  }

  svg {
    fill: white;
  }
}

.selectedIcon {
  background-color: map-get($colors, light-blue);
  box-shadow: 0 4px 15px 0 rgba(map-get($colors, light-blue), 0.4);

  img {
    height: 11px;
    margin: 1px 2px 0 0;
  }
}

.unselectedIcon {
  background-color: map-get($colors, white);
}

.icon {
  min-width: 22px;
  margin: 0 10px;
  vertical-align: text-bottom;
}

.tooltip {
  font-family: $ff-lato;
  font-size: map-get($sizes, smaller);
}
