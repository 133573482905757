@import '~styles/index.scss';

.container {
  overflow: hidden;
}

.owner {
  display: flex;
  padding: 15px;
  align-items: center;
}

.ownerInfo {
  display: block;
  margin: 0 0 8px;
  font-size: map-get($sizes, bigger);
  font-weight: $fw-bold;
}

.carImg {
  max-width: 100px;
  margin-right: 1em;
}

.carInfo {
  display: block;
  font-size: map-get($sizes, regular);
  color: map-get($colors, font-gray);
}

.section {
  padding: 15px;

  &Total {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    span {
      font-size: map-get($sizes, bigger);
      font-weight: $fw-bold;
    }
  }
}

.data {
  padding: 5px 25px;

  .item {
    padding: 5px 0;
  }

  .title {
    margin-bottom: 0.5em;
  }

  .content {
    font-size: map-get($sizes, smaller);
    color: map-get($colors, font-gray);
  }
}

.header {
  padding: 15px;
  font-size: map-get($sizes, smaller);
  border-top: 1px solid map-get($colors, darker-gray);
  background-color: map-get($colors, light-gray);
}

.service {
  display: flex;
  padding: 10px 15px;
  font-weight: $fw-normal;
  flex-direction: column;
  align-items: flex-start;

  :global {
    .PackageItems {
      margin-top: 20px;
    }
  }

  & + & {
    border-top: 1px solid map-get($colors, darker-gray);
  }

  .body {
    display: flex;
    width: 100%;
  }

  .name {
    display: flex;
    align-items: center;
  }
}

.price {
  display: flex;
  margin-top: 0.5em;
  align-items: center;

  &Label {
    font-size: map-get($sizes, smaller);
    color: map-get($colors, font-gray);
  }

  &Value {
    margin-left: 5px;
    font-size: map-get($sizes, smaller);
  }
}

.additionalInfo {
  margin-top: 0.5em;
  align-items: center;

  &Row {
    display: flex;
    margin-top: 0.5em;
    align-items: center;
  }

  &Key {
    display: block;
    font-size: map-get($sizes, smaller);
    color: map-get($colors, font-gray);
  }

  &Value {
    display: block;
    margin-top: 0.2em;
    font-size: map-get($sizes, smaller);
  }

  &Row &Value {
    margin-top: 0;
    padding-left: 0.3em;
  }
}

.icon {
  width: 30px;
  height: 30px;
  margin-right: 15px;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: map-get($colors, darker-gray);
}

.total {
  margin-bottom: 0.5em;
  font-size: map-get($sizes, bigger);
  font-weight: $fw-bold;

  &Section {
    padding: 15px 15px 5px;
  }
}

.advisorSection {
  display: flex;
  padding: 15px;
  border-top: 1px solid map-get($colors, darker-gray);
  align-items: center;
}

.advisorInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.advisorText {
  font-family: $ff-lato;
  font-size: map-get($sizes, smaller);
  color: map-get($colors, darkest-gray);
}

.advisorName {
  margin-top: 5px;
  font-family: $ff-lato;
  font-size: map-get($sizes, regular);
  font-weight: $fw-bold;
}

.advisorPhoto {
  @include sizing(auto 50px);

  max-width: 50px;
  margin-right: 10px;
  border-radius: 50%;
}

.estimated {
  padding-bottom: 15px;

  .container {
    display: flex;
    padding-left: 15px;
    flex-direction: column;
  }

  .item {
    margin-top: 15px;
    margin-bottom: 5px;
    padding: 0 15px 0 15px;
  }

  .label {
    color: map-get($colors, font-gray);
  }

  .value {
    font-size: map-get($sizes, smaller);
  }
}
