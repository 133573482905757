* {
  box-sizing: border-box;
  outline: none;
}

p,
ul,
li,
h2 {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

button {
  border: none;
}
