@import '~styles/index.scss';

.conciergeHeader {
  position: relative;
  z-index: $z-index-10;
  height: 56px;
  border-bottom: 1px solid $color-dark-gray;
  color: map-get($colors, white);
  background-color: map-get($colors, main-black);

  &Centering {
    @include horizontally-centered;

    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
  }
}

.conciergeReportsButton,
.conciergeQRButton {
  cursor: pointer;

  &Img {
    height: 20px;
  }
}

.conciergeLogo {
  height: 29px;
}
