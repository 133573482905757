@import '~styles/index.scss';

.conciergeAlert {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 100%;
  padding: 16px 32px;
  font-family: $ff-ibm;
  font-size: $fs-regular-bigger;
  font-style: $fs-normal;
  font-weight: $fw-normal;
  line-height: 16px;
  text-align: center;
  border-radius: 4px;
  color: map-get($sizes, black);
  background-image:
    linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.025) 25%,
      transparent 25%,
      transparent 50%,
      rgba(0, 0, 0, 0.025) 50%,
      rgba(0, 0, 0, 0.025) 75%,
      transparent 75%,
      transparent
    );
  background-size: 64px 64px;
  align-items: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;

  &_success {
    color: map-get($colors, white);
    background-color: map-get($colors, green);
  }

  &_brand {
    color: map-get($colors, white);
    background-color: map-get($colors, primary);
  }

  &_brand-outline {
    border: 1px solid map-get($colors, primary);
    color: map-get($colors, primary);
    background-color: map-get($colors, white);
  }

  &_error {
    color: map-get($colors, white);
    background-color: map-get($colors, red);
  }

  &_error-outline {
    border: 1px solid map-get($colors, red);
    color: map-get($colors, red);
    background-color: map-get($colors, white);
  }

  &_warning {
    color: map-get($colors, white);
    background-color: map-get($colors, warning);
  }

  &_warning-outline {
    border: 1px solid map-get($colors, warning);
    color: map-get($colors, warning);
    background-color: map-get($colors, white);
  }
}
