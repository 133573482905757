@import '~styles/index.scss';

.label {
  margin-bottom: 2px;
  font-family: $ff-lato;
  font-size: $fs-smaller;
  line-height: 18px;
}

.form {
  display: flex;

  :global(.conciergeInputWrapper) {
    width: 100%;
  }
}

.button {
  width: 72px;
  margin: 8px 8px 0 0;
  color: map-get($colors, white);
  background: map-get($colors, blueish-gray);
}

.buttonsContainer {
  display: flex;
}

.addButton {
  @include sizing(52px 44px);

  margin: 8px 0 0 12px;
  font-family: $ff-ibm;
  color: map-get($colors, white);
  background-color: map-get($colors, blueish-gray);
}

.item {
  font-family: $ff-ibm;
  font-size: map-get($sizes, slightly-bigger);
}

.remove {
  @include sizing(16px);

  margin-right: 6px;
  padding: 0 6px 3px;
  font-size: map-get($sizes, smaller);
  border: none;
  color: map-get($colors, white);
  background: map-get($colors, blueish-gray);
}

.container {
  display: flex;
  margin-bottom: 10px;
}

.extra {
  line-height: 16px;
}
