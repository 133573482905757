@import '~styles/index.scss';

.additionalGroup {
  display: flex;
  margin-bottom: 24px;
  padding: 24px;
  border: 1px solid map-get($colors, darker-gray);
  border-radius: 6px;
  background-color: map-get($colors, white);
  flex-wrap: wrap;

  &Wrapper {
    display: flex;
    width: 100%;
    line-height: 48px;
    column-gap: 50px;
    grid-template-columns: 1fr 1fr;
  }

  &Switch {
    &Container {
      display: flex;
      width: 100%;
      height: 48px;
    }

    &Label {
      margin-top: auto;
      margin-bottom: auto;
      font-family: $ff-lato;
      font-size: map-get($sizes, smaller);
      line-height: 18px;
      color: map-get($colors, main-black);
    }

    &Toggle {
      margin-top: auto;
      margin-bottom: auto;
      margin-left: 10px;
    }
  }

  &ToggleButton {
    &Disabled {
      cursor: not-allowed;

      &:hover:not(:disabled) {
        background: white;
      }

      &.conciergeButtonAqua {
        &:hover:not(:disabled) {
          background: linear-gradient(0deg, #0bcaf90d, #0bcaf90d), #fff;
        }
      }
    }

    &:not(:first-child) {
      margin-left: 5px;
    }

    &Wrapper {
      .additionalGroupToggleButtonRow:not(:first-of-type) {
        margin-top: 16px;
      }
    }

    &WrapperLabel {
      font-family: $ff-lato;
      font-size: map-get($sizes, smaller);
      color: map-get($colors, main-black);
    }

    &Row {
      display: flex;

      &:last-child {
        margin-bottom: 15px;
      }
    }

    &Label {
      display: block;
      max-width: 450px;
      margin-top: auto;
      margin-right: 20px;
      margin-left: 8px;
      font-family: $ff-lato;
      font-size: map-get($sizes, smaller);
      color: map-get($colors, main-black);
    }
  }

  &Input {
    &Wrapper {
      width: 50%;

      .additionalGroupInputContainer:not(:first-child) {
        margin-top: 16px;
      }
    }

    &Container {
      display: flex;
      flex-direction: column;
    }

    &Label {
      display: block;
      min-width: 350px;
      max-width: 450px;
      margin-top: auto;
      font-family: $ff-lato;
      font-size: map-get($sizes, smaller);
      color: map-get($colors, main-black);
    }

    &TextInput {
      width: 100%;
      height: 48px;
      padding: 14px;
      font-family: $ff-lato;
      font-size: map-get($sizes, regular);
      line-height: 20px;
      border: 1px solid map-get($colors, darker-gray);
      border-radius: 6px;
      color: map-get($colors, main-black);
      background-color: map-get($colors, white);

      &:disabled {
        background: #e9ecef;
      }
    }
  }

  &ActionButtons {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    text-align: right;
    justify-content: right;

    button:not(:first-child) {
      margin-left: 16px;
    }
  }
}
