@import '~styles/index.scss';

.mainPage {
  @include horizontally-centered;

  padding: 16px 0;
}

.navbar {
  display: flex;
  margin-bottom: 16px;
  align-items: center;
  justify-content: flex-end;
}

.table {
  :global {
    .ReactTable {
      .rt-tbody .rt-td {
        padding-left: 25px;
      }

      .rt-table {
        overflow: hidden;
      }
    }
  }
}

.loadingPanel {
  @include main-font(map-get($sizes, bigger));

  width: 100%;
  text-align: center;
  color: map-get($colors, pink);
}

.tooltip {
  overflow: hidden;
  max-width: 150px;
  font-family: $ff-lato;
  font-size: map-get($sizes, smaller);
  text-align: center;
}
