@import "~styles/index.scss";

.dropdown {
  width: 48%;
  padding-bottom: 20px;

  :global(.conciergeDropdownSelectedField) {
    width: 100%;
    margin: 3px 0;
  }
}

.label {
  @include main-font(map-get($sizes, smaller));
}
