@import '~styles/index.scss';

.container {
  display: flex;
  flex-flow: column nowrap;
}

.buttons {
  display: flex;
  margin-top: 1em;
  align-items: center;
  justify-content: flex-end;
}

.current {
  display: flex;
  margin-right: auto;
  font-size: map-get($sizes, big);
  flex-direction: column;

  &Text {
    margin-top: 5px;
    font-size: map-get($sizes, regular);
    color: map-get($colors, font-gray);
  }
}

.loading {
  @include main-font(map-get($sizes, bigger));

  width: 100%;
  text-align: center;
  color: map-get($colors, darkest-gray);
}
