@import '~styles/index.scss';

.conciergeButton {
  display: flex;
  height: fit-content;
  font-family: $ff-ibm;
  font-size: map-get($sizes, regular);
  font-style: $fs-normal;
  font-weight: $fw-normal;
  line-height: map-get($sizes, slightly-bigger);
  text-align: center;
  border-radius: 6px;
  background: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &SmallPadding {
    padding: 6px 9px;
  }

  &MediumPadding {
    padding: 9px 12px;
  }

  &LargePadding {
    padding: 16px 24px;
  }

  &FullWidth {
    width: 100%;
  }

  &Square {
    width: 40px;
    height: 40px;
    border-radius: 4px;
  }

  &:disabled {
    border: 1px solid map-get($colors, darker-gray);
    color: map-get($colors, white);
    background: map-get($colors, darker-gray);
    cursor: not-allowed;
  }

  &BaseGrey {
    border: 1px solid map-get($colors, font-gray);
    color: map-get($colors, font-gray);

    &:disabled {
      border: 1px solid map-get($colors, font-gray);
      color: map-get($colors, font-gray);
      background: none;
      cursor: not-allowed;
    }
  }

  &Neutral {
    border: 1px solid map-get($colors, darker-gray);
    background: map-get($colors, white);

    &:hover:not(:disabled) {
      background: map-get($colors, darker-white);
    }
  }

  &Aqua {
    border: 1px solid map-get($colors, light-blue);
    background: $color-light-blue-gradient;
    box-shadow: 0 4px 10px rgba(27, 43, 41, 0.08);

    &:hover:not(:disabled) {
      background: $color-light-blue-gradient-secondary;
    }
  }

  &Brand {
    border: 1px solid map-get($colors, primary);
    color: map-get($colors, white);
    background: map-get($colors, button-gradient);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.18);

    &:hover:not(:disabled) {
      background: map-get($colors, primary-gradient);
    }
  }

  &BrandOutline {
    border: 1px solid map-get($colors, primary);
    color: map-get($colors, primary);
    background: map-get($colors, white);

    &:hover:not(:disabled) {
      border: 1px solid map-get($colors, primary-active);
      color: map-get($colors, primary-active);
    }
  }

  &Dark {
    border: 1px solid $color-black;
    color: map-get($colors, white);
    background: $color-black;

    &:hover:not(:disabled) {
      background: $color-black;
    }
  }

  &DarkOutline {
    border: 1px solid map-get($colors, black);
    background: map-get($colors, white);
  }

  &Success {
    border: 1px solid map-get($colors, green2);
    color: map-get($colors, white);
    background: map-get($colors, green2);

    &:hover:not(:disabled) {
      background: map-get($colors, green3);
    }
  }

  &Destructive {
    border: 1px solid map-get($colors, pink);
    color: map-get($colors, white);
    background: map-get($colors, pink);

    &:hover:not(:disabled) {
      background: #fd3075;
    }
  }

  &DestructiveOutline {
    border: 1px solid map-get($colors, pink);
    border: 1px solid map-get($colors, #fdfdfd);
    color: map-get($colors, pink);
    background: #fdfdfd;
    box-shadow: 0 4px 10px rgba(27, 43, 41, 0.08);

    &:hover:not(:disabled) {
      background: $color-pink-gradient;
    }
  }

  &PrimaryOutline {
    border: 1px solid map-get($colors, primary);
    color: map-get($colors, primary);
  }

  &WithoutBorder {
    border-color: transparent;
  }

  &Booked {
    position: relative;
    display: flex;
  }

  img {
    width: 16px;
    height: 16px;
  }

  :not(:last-child) {
    margin-right: 6px;
  }

  .leftIcon:not(:last-child) {
    margin-right: 6px;
  }

  .rightIcon:not(:first-child) {
    margin-left: 6px;
  }

  .bookedCounter {
    @include absolute(top -10px right -10px);

    display: flex;
    min-width: 20px;
    min-height: 20px;
    font-size: map-get($sizes, smaller);
    border-radius: 100px;
    color: map-get($colors, white);
    background: map-get($colors, light-blue);
    align-items: center;
    justify-content: center;
  }
}
