@import '~styles/index.scss';

.dropdownContainer {
  width: 60%;

  :global(.StyledSelect__menu) {
    height: 200px;
  }
}

.container {
  display: flex;
  min-height: 200px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.inputContainer {
  width: 48%;
  padding: 15px 0;

  :global(.conciergeInputLabel) {
    font-size: map-get($sizes, smaller);
  }

  :global(.conciergeInputWrapper) {
    width: 100%;
  }
}

.selectWrapper {
  width: 48%;
  height: 85px;
  margin: 5px 0 0;
  padding: 15px 0;

  &:nth-child(even) {
    padding-left: 20px;
  }
}

.label {
  font-family: $ff-lato;
  font-size: map-get($sizes, smaller);
  line-height: 18px;
  color: map-get($colors, main-black);
}

.input {
  @include sizing(100% 48px);

  margin: 4px 0 3px;
  padding: 14px 16px;
  font-family: $ff-lato;
  font-size: map-get($sizes, regular);
  line-height: 20px;
  border: 1px solid map-get($colors, darker-gray);
  border-radius: 6px;
  color: map-get($colors, main-black);
  background-color: map-get($colors, white);
}

.switch {
  display: flex;
  width: 48%;
  align-items: center;

  &Label {
    padding-right: 10px;
    font-family: $ff-lato;
    font-size: map-get($sizes, smaller);
    line-height: 18px;
    color: map-get($colors, main-black);
  }
}
