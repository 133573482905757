@import '~styles/index.scss';

.conciergeSettingsPageCapacityTabPanel {
  .conciergePanelBody {
    padding: 0;
  }

  .photo {
    @include sizing(80px);

    border-radius: 50%;
  }

  .ReactTable {
    border: none;
    border-radius: 0 0 6px 6px;

    .rt-table {
      overflow: visible;
    }

    .rt-tbody {
      overflow: visible;

      .rt-tr {
        max-height: 100px;
        align-items: center;
      }

      .rt-td {
        padding: 12px 12px 12px 30px;
      }
    }

    .rt-noData {
      top: 45%;
    }

    .-pagination {
      padding: 15px;
      border-top: $default-border;
      box-shadow: none;

      .-previous,
      .-center,
      .-next {
        flex: none;
      }

      .-pageSizeOptions {
        @include main-font;
      }
    }
  }
}

.conciergeSettingsPageCapacityTabSwitch {
  display: flex;
  margin-left: auto;
  padding: 0 1em;
  align-items: center;

  & label {
    margin-right: 0.5em;
  }
}

.employeesSearch {
  position: relative;
  display: flex;
  width: 300px;
  height: fit-content;
  padding: 10px;
  border: $default-border;
  border-radius: $small-border-radius;
  background-color: map-get($colors, white);
  justify-content: space-between;

  &Input {
    @include main-font;

    width: 100%;
    outline: none;
    border: none;

    &::placeholder {
      color: map-get($colors, font-gray);
    }
  }

  &Icon {
    cursor: pointer;
  }
}
