@import "~styles/index.scss";

.panel {
  :global {
    .conciergeBlockContent {
      background-color: map-get($colors, light-gray);
    }

    .conciergePanelBody {
      display: flex;
      padding: 0;
      flex-direction: column;
    }
  }
}

.selectedOption {
  @include main-font(map-get($sizes, regular));

  margin-right: 5px;
  margin-left: auto;
  color: map-get($colors, font-gray);
}

.section {
  display: flex;
  height: 48px;
  padding: 15px;
}
