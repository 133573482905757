@import '~styles/index.scss';

.radioContainer {
  display: flex;
  flex-flow: column nowrap;
}

.container {
  display: flex;
}

.question {
  margin: 0 0 8px;
}

.loading {
  @include main-font(map-get($sizes, bigger));

  width: 100%;
  text-align: center;
  color: map-get($colors, darkest-gray);
}

.button {
  margin-right: 10px;
  padding: 16px 32px;
  font-family: $ff-ibm;
  font-size: map-get($sizes, slightly-bigger);
  line-height: 16px;
  text-transform: capitalize;
  border: 1px solid map-get($colors, darker-gray);
  border-radius: 6px;
  color: map-get($colors, white);
  background: map-get($colors, button-gradient);
  box-shadow: 0 4px 10px 0 rgba(map-get($colors, shadow-gray), 0.08);
  cursor: pointer;

  &.selected {
    height: 48px;
    outline: 1px solid map-get($colors, light-blue);
    color: map-get($colors, light-blue);
    background: rgba(map-get($colors, light-blue), 0.05);
  }
}

.wrapper {
  display: flex;
  margin: 20px 0 0;
  justify-content: space-between;

  &Button {
    margin-left: auto;
  }

  &Text {
    color: map-get($colors, font-gray);
  }
}

.select {
  margin: 14px 0 12px;
  line-height: 20px;
}
