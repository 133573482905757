@import '~styles/index.scss';

.vehicleGroup {
  display: flex;
  margin-bottom: 16px;
  padding: 24px;
  border: 1px solid map-get($colors, darker-gray);
  border-radius: 6px;
  background-color: map-get($colors, white);
  flex-wrap: wrap;

  &Button {
    min-width: 210px;
    height: 48px;
    margin-bottom: 16px;
  }

  &Cars {
    min-width: 100%;
    padding-right: 15px;

    &Container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      &Item {
        display: flex;
        overflow: hidden;
        box-sizing: border-box;
        margin: 2px;
        padding: 3px;
        padding-left: 6px;
        font-family: $ff-lato;
        font-size: map-get($sizes, smaller);
        border-radius: 2px;
        white-space: nowrap;
        background-color: hsl(0, 0%, 90%);
        text-overflow: ellipsis;

        p:first-child {
          font-weight: $fw-bold;
        }

        p:not(:first-child) {
          margin-left: 8px;
        }
      }
    }
  }

  &Error {
    display: flex;
    height: 13px;
    margin-top: 4px;

    &Label {
      font-family: $ff-lato;
      font-size: map-get($sizes, smaller);
      color: map-get($colors, pink);
    }
  }

  &Checkbox {
    &Label {
      margin: 5px;
      font-family: $ff-lato;
      font-size: map-get($sizes, smaller);
      line-height: 18px;
    }

    &Container {
      margin-left: auto;
    }
  }

  &Input {
    &Wrapper {
      display: flex;
      flex-wrap: wrap;
    }

    &Container {
      display: flex;
      margin-right: 14px;
      flex-direction: column;
    }

    &Separator {
      width: 100%;
    }

    &Label {
      margin-bottom: 2px;
      font-family: $ff-lato;
      font-size: map-get($sizes, smaller);
      line-height: 18px;
      color: map-get($colors, main-black);
    }

    &Dropdown {
      &__control {
        height: 100%;
        font-family: $ff-lato;
        font-size: map-get($sizes, regular);
        line-height: 20px;
        border: 1px solid map-get($colors, darker-gray) !important;
        border-radius: 6px;
        color: map-get($colors, white);
        background-color: map-get($colors, white) !important;

        &--is-disabled {
          background-color: #e9ecef !important;
        }
      }

      &__menu {
        display: flex;
        padding: 8px 0;
        border: 1px solid map-get($colors, darker-gray);
        border-radius: 10px;
        background-color: map-get($colors, white);
        box-shadow: 0 12px 30px 0 rgba(map-get($colors, black), 0.16);
        flex-direction: column;

        .menuHidden & {
          display: none;
        }

        &-list {
          padding: 0;
          font-family: $ff-lato;

          .vehicleGroupInputDropdown__option {
            display: flex;
            height: 44px;
            font-family: $ff-lato;
            font-size: map-get($sizes, regular);
            line-height: 20px;
            border: none;
            color: map-get($colors, main-black);
            align-items: center;

            &--is-selected {
              background-color: rgba(map-get($colors, light-blue), 0.1);
            }

            &--is-focused {
              background-color: rgba(map-get($colors, light-blue), 0.05);
            }
          }
        }
      }

      &.error {
        .vehicleGroupInputDropdown__control {
          border-color: map-get($colors, pink) !important;
        }
      }
    }

    &RangeText {
      margin: 12px 12px 0 0;
      font-family: $ff-lato;
      font-size: map-get($sizes, regular);
      line-height: 20px;
      color: map-get($colors, darkest-gray);
    }

    &TextInput {
      min-width: 210px;
      height: 48px;
      padding: 14px;
      font-family: $ff-lato;
      font-size: map-get($sizes, regular);
      line-height: 20px;
      border: 1px solid map-get($colors, darker-gray);
      border-radius: 6px;
      color: map-get($colors, main-black);
      background-color: map-get($colors, white);

      &.error {
        border-color: map-get($colors, pink);
      }

      &:disabled {
        background: #e9ecef;
      }
    }
  }

  &ExcludeCarsSwitch {
    margin-left: 15px;

    &Container {
      display: flex;
      margin: 0 24px 20px 0;
      align-items: center;
    }
  }

  &ActionButtons {
    display: flex;
    width: 100%;
    margin-top: 4px;

    &RightAlignment {
      display: flex;
      width: 100%;
      margin-top: 4px;
      justify-content: flex-end;

      button:not(:first-child) {
        margin-left: 16px;
      }
    }
  }

  &ActionButton {
    height: 50px;
    margin-right: 8px;
    padding: 0 24px;
    font-family: $ff-ibm;
    font-size: map-get($sizes, slightly-bigger);
    line-height: 16px;
    border-radius: 6px;
    color: map-get($colors, white);
    background-color: map-get($colors, blueish-gray);
    cursor: pointer;

    &-delete {
      border: 1px solid map-get($colors, warning-red);
      color: map-get($colors, warning-red);
      background: transparent;
    }

    &-marginLeft {
      margin-left: 15px;
    }

    &-success {
      border: 1px solid map-get($colors, black);
      color: map-get($colors, black);
      background: transparent;
    }
  }

  &QuickAdd {
    &Label {
      width: 100%;
    }

    &Filters {
      display: flex;
      flex-wrap: wrap;
    }

    &Container {
      display: flex;
      width: 100%;
      flex-wrap: wrap;

      .vehicleGroupInputLabel {
        display: flex;
        width: 100%;
      }

      .vehicleGroupInputDropdown {
        min-width: 210px;
        height: 48px;
        margin-right: 14px;
        margin-bottom: 16px;
      }
    }

    &Button {
      @include main-font;
      @include sizing(80px 48px);

      margin-right: 20px;
      outline: none;
      border: none;
      border-radius: 6px;
      color: map-get($colors, white);
      background: map-get($colors, blueish-gray);
      cursor: pointer;

      &Disabled {
        background: map-get($colors, darker-gray);
        cursor: not-allowed;
      }
    }
  }
}
