@import "~styles/index.scss";

.capacityDashboard {
  position: relative;
  overflow: hidden;
  border: 1px solid $color-light-gray;
  border-bottom: none;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: map-get($colors, white);

  &LoadingOverlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: $z-index-one;
    display: flex;
    width: 100%;
    height: 100%;
    font-family: $ff-lato;
    background-color: white;
    opacity: 0.7;
    align-items: center;
    justify-content: center;
  }

  &Row {
    display: flex;

    &Pale {
      background-color: $color-pale-blue;
    }

    & + & {
      border-top: 1px solid $color-light-gray;
    }

    &Item {
      position: relative;
      display: flex;
      width: inherit;
      min-height: 58px;
      margin: 0;
      padding: 12px 24px;
      font-family: $ff-lato;
      font-size: map-get($sizes, regular);
      font-style: $fs-normal;
      line-height: 24px;
      background: inherit;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;

      &Selected {
        color: map-get($colors, mint);
      }

      &Pinned {
        min-width: 20%;
        text-align: left;
        border-right: 1px solid $color-light-gray;
        background-color: map-get($colors, white);
      }

      &Pale {
        background-color: $color-pale-blue;
      }

      &Top {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
      }

      &Avatar {
        width: 24px;
        height: 24px;
        border: 1px solid $color-light-gray;
        border-radius: 50%;
      }

      &Container {
        display: flex;
        overflow: hidden;
        padding-right: 8px;
        white-space: nowrap;
        align-items: center;
        text-overflow: ellipsis;
      }

      &Title {
        overflow: hidden;
        font-weight: $fw-bold;
        white-space: nowrap;
        text-overflow: ellipsis;

        &--padding-left {
          overflow: hidden;
          padding-left: 8px;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      &Toggle {
        margin-right: auto;
        padding: 0 4px;
        background: none;

        img {
          width: 16px;
        }
      }

      &Counter {
        color: $color-gray;
      }
    }
  }

  &Progress {
    display: flex;
    width: 100%;
    height: 20px;
    margin-top: 4px;
    align-items: center;
    justify-content: space-between;

    &Bar {
      width: 100%;
      height: 4px;
      border-radius: 8px;
      background-color: $color-light-gray;

      &Value {
        max-width: 100%;
        height: 100%;
        border-radius: 8px;
        background-color: map-get($colors, light-blue);
      }

      &Blocked {
        background-color: $color-warning-red;
      }
    }

    .blockActivitySwitch {
      margin-left: 8px;
    }
  }

  &Carousel {
    &Outer {
      display: flex;
      min-width: 80%;
    }

    &Wrapper {
      width: calc(100% - 72px);

      &NoButtons {
        width: 100%;
      }
    }

    .slider-slide + .slider-slide {
      border-left: 1px solid $color-light-gray;
    }

    .slider-slide:last-of-type {
      border-right: 1px solid $color-light-gray;
    }
  }
}
