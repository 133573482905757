@import "~styles/index.scss";

.additionalInfoItemColumn {
  display: flex;
  margin-right: 15px;
  flex-direction: column;

  .conciergeInputLabel {
    margin-bottom: 8px;
  }

  .conciergeInputWrapper {
    margin: 0;

    .conciergeInput {
      margin: 0;
    }
  }

  .checkbox {
    @include sizing(18px);

    border: 1px solid map-get($colors, darker-gray);
    border-radius: 3px;

    &Active {
      @include flex-centered;

      border: none;
      background: map-get($colors, light-blue);
    }

    &Row {
      display: flex;
      width: 160px;
      margin-top: 10px;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
    }

    &Title {
      margin-left: 7px;
      font-size: map-get($sizes, smaller);
    }
  }

  &.fullWidth {
    width: 100%;
  }

  .additionalInfoItemTimeWrapper {
    @include sizing(230px 44px);

    display: flex;
    overflow: hidden;
    border: 1px solid map-get($colors, darker-gray);
    border-radius: 6px;

    .conciergeDealershipTimeFieldInput {
      height: 100%;
      margin: 0;
      padding: 0;
      border: none;
    }

    .watchIcon {
      margin: 0 15px;
    }
  }

  .additionalInfoItemLocation {
    &Wrapper {
      position: relative;

      .autocomplete-dropdown-container {
        position: absolute;
        z-index: $z-index-20;
        display: block;
        overflow: auto;
        max-height: 80px;
        margin-top: 8px;
        padding: 5px 0;
        border: 1px solid map-get($colors, darker-gray);
        border-radius: 6px;
        background-color: map-get($colors, white);

        .suggestion-item {
          padding: 5px 15px;
          background-color: map-get($colors, white);
          cursor: "pointer";

          &--active {
            background-color: map-get($colors, darker-gray);
          }
        }
      }
    }

    &Input {
      @include sizing(100% 44px);

      display: flex;
      padding: 0 15px;
      border: 1px solid map-get($colors, darker-gray);
      border-radius: 6px;

      &:disabled {
        color: map-get($colors, font-gray);
        background-color: map-get($colors, white);
      }
    }
  }

  .conciergeDropdownWrapper {
    .conciergeDropdownSelectedField {
      @include sizing(230px 44px);
    }

    .conciergeDropdownMenu {
      overflow: hidden;
      width: 230px;
    }
  }
}
