@import '~styles/index.scss';

.conciergeApp {
  .page {
    height: calc(100% - #{$header-height});
    min-height: calc(100vh - #{$header-height});
  }
}

.conciergeApp.withReports {
  .page {
    height: calc(100% - #{$header-height-with-reports});
    min-height: calc(100vh - #{$header-height-with-reports});
  }
}

.page {
  :global {
    .conciergePanel {
      width: 100%;
      margin: 35px 0;
    }

    .conciergePanelHeader {
      display: flex;
      font-family: $ff-lato;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.main {
  @include horizontally-centered;

  padding-bottom: 20px;
}

.panel {
  width: 100%;
  margin: 35px 0;

  :global {
    .conciergeInputWrapper {
      width: 50%;

      &:nth-child(odd) {
        padding-right: 20px;
      }

      &:nth-child(even) {
        padding-left: 20px;
      }
    }

    .conciergePanelBody {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.loadingPanel {
  @include main-font(map-get($sizes, bigger));

  text-align: center;
  color: map-get($colors, pink);
}

.goBackButton {
  @include main-font;

  display: flex;
  text-decoration: none;
  color: $color-black;
  background-color: inherit;
  align-items: baseline;
  cursor: pointer;

  img {
    position: relative;
    bottom: -3px;
  }
}

.inputWrapper {
  @include main-font;

  width: 50%;
  margin-top: 5px;
  margin-bottom: 20px;
  padding-right: 20px;
}

.maintainers {
  :global {
    .conciergePanelBody {
      padding: 0;
    }

    .ReactTable {
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #dedee0;

      .rt-table {
        overflow: visible;
      }

      .rt-tbody {
        overflow: visible;
      }
    }
  }

  .buttons {
    display: flex;
    margin: 20px;
    justify-content: flex-end;
  }
}

.select {
  :global(.StyledSelect__menu) {
    height: 150px;
  }
}
