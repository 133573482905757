@import 'styles/index.scss';

.packageItemsContainer {
  position: relative;
  width: 100%;
}

.header {
  display: flex;
  padding: 16px;
  padding-right: 20px;
  font-family: $ff-lato;
  font-size: $fs-smaller;
  line-height: 18px;
  color: $color-silver-gray;
  background: $color-darker-white;
  justify-content: space-between;

  .isSelected & {
    color: $color-aqua;
    background: rgba($color-aqua, 0.05);
  }
}

.packageItems {
  z-index: $z-index-negative;
  display: flex;
  overflow: hidden;
  max-height: 0;
  transition: max-height 350ms ease-in-out;
  flex-direction: column;
}

.shown {
  z-index: $z-index-one;
  max-height: 1200px;
  transition: max-height 350ms ease-in-out;
}

.body {
  display: flex;
  padding: 16px;
  font-family: $ff-lato;
  font-size: $fs-smaller;
  flex-direction: column;
}

.title {
  padding: 6px 0;
  font-family: $ff-lato;
  font-size: $fs-smaller;
  color: $color-silver-gray;
}

.listItem {
  font-family: $ff-lato;
  font-size: $fs-smaller;
}
