@import '~styles/index.scss';

.conciergeModal {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: $z-index-2;
  display: flex;
  max-height: 85%;
  border: 1px solid map-get($colors, darker-gray);
  border-radius: 10px;
  background-color: map-get($colors, white);
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 10px 0 rgba(map-get($colors, shadow-gray), 0.08);
  flex-direction: column;

  &Small {
    width: 554px;
  }

  &Medium {
    width: 768px;
  }

  &Large {
    width: 1024px;
  }

  &Header {
    display: flex;
    height: 64px;
    padding: 20px 24px;
    font-family: $ff-lato;
    font-size: map-get($sizes, much-bigger);
    line-height: 24px;
    border-radius: 10px 10px 0 0;
    border-bottom: 1px solid map-get($colors, darker-gray);
    color: map-get($colors, black);
    background-color: map-get($colors, light-gray);
    align-items: center;
    justify-content: space-between;

    &Subtitle {
      font-style: italic;
      color: map-get($colors, gray);
    }

    &Separator {
      width: 1px;
      height: 24px;
      margin-right: auto;
      margin-left: 12px;
      padding-left: 12px;
      border-left: 1px solid #9ba4b5;
    }
  }

  &Body {
    overflow: auto;
    width: 100%;
    padding: 24px;
    white-space: normal;
    flex: 1;

    &Label {
      @include main-font;

      line-height: 18px;
      white-space: normal;
      color: map-get($colors, main-black);
      word-break: break-word;
    }

    span {
      @include main-font;

      line-height: 18px;
      color: map-get($colors, main-black);
    }
  }

  &Footer {
    display: flex;
    width: 100%;
    padding: 24px;
    justify-content: flex-end;

    button {
      width: 180px;
    }

    button:not(:first-child) {
      margin-left: 18px;
    }
  }

  &Overlay {
    @include fixed(top 0 left 0);
    @include sizing(100vw 100vh);

    z-index: $z-index-1;
    background-color: rgba(map-get($colors, darkest-gray), 0.95);
  }

  &Container {
    display: contents;
  }
}
