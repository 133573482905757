@import "~styles/index.scss";

.tooltip {
  &Container {
    width: 350px;
    border-radius: $small-border-radius !important;
    white-space: normal;
    opacity: 1 !important;
  }

  &Content {
    display: flex;
    padding: 5px;
    font-family: $ff-lato;
    font-size: map-get($sizes, smaller);
    color: map-get($colors, black);
  }
}

.bookingsDetails {
  display: flex;
  padding: 5px;
  font-family: $ff-lato;
  color: map-get($colors, black);
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  &Service {
    &Icon {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
  }

  &ServiceName {
    font-size: map-get($sizes, smaller);
  }

  &ServicePrice {
    &Label {
      font-size: map-get($sizes, smaller);
      color: map-get($colors, font-gray);
    }

    &Value {
      margin-left: 3px;
      font-size: map-get($sizes, smaller);
    }
  }
}
