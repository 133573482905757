@import '~styles/index.scss';

.timePicker {
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  width: 100%;
  max-width: 350px;
  min-height: 243px;
  font-family: $ff-lato;
  border: thin solid map-get($colors, darker-gray);
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 4px 10px rgba(27, 43, 41, 0.08);

  &View {
    display: flex;
    padding: 15px 12px 15px 12px;
    flex-wrap: wrap;
    justify-content: space-between;

    .textContainer {
      display: flex;
      text-align: center;
      flex: 1;
      align-items: center;
      justify-content: center;
    }

    &Btn {
      margin-bottom: 10px;
      padding: 6px;
      font-family: $ff-lato;
      font-size: map-get($sizes, regular);
      line-height: 18px;
      border: 1px solid transparent;
      border-radius: 10px;
      color: map-get($colors, dark-gray);
      background-color: #f9fafc;
      cursor: pointer;
    }

    &Btn:disabled,
    &BtnDisabled {
      color: map-get($colors, font-gray);
      cursor: default;
    }

    & .active {
      box-sizing: border-box;
      font-weight: $fw-bold;
      border: 1px solid #0bcaf9;
      border-radius: 10px;
      background: linear-gradient(0deg, rgba(11, 202, 249, 0.05), rgba(11, 202, 249, 0.05)), #fff;
      box-shadow: 0 4px 10px rgba(27, 43, 41, 0.08);
    }

    &::after {
      max-width: 78.45px;
      content: "";
      flex: 1 1 100%;
    }
  }

  & .btn {
    outline: none;
  }

  &Popup {
    z-index: $z-index-1;
    width: calc(100% - 20px);
    padding: 20px;
    text-align: center;
    border: thin solid map-get($colors, darker-gray);
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 4px 10px rgba(27, 43, 41, 0.08);

    &Title {
      margin-bottom: 1em;
    }

    &Button {
      padding: 2px 16px;
      border: thin solid map-get($colors, darker-gray);
      border-radius: 4px;
      background-color: #fff;
      cursor: pointer;

      & + & {
        margin-left: 10px;
      }

      &Dark {
        border: thin solid #000;
        color: $color-white;
        background-color: #000;
      }
    }
  }
}

.conciergeSettingsCapacityTooltip {
  z-index: $z-index-1;
}

.isEmpty {
  .timePickerView {
    height: inherit;
    align-items: center;
  }

  .timePickerView::after {
    max-width: 0;
  }
}
