@import '~styles/index.scss';

.table {
  :global {
    .ReactTable {
      cursor: pointer;

      .rt-th {
        text-align: center;
      }

      .rt-td {
        text-align: center;
      }

      .rt-noData {
        padding-top: 80px;
      }
    }
  }
}

.loading {
  @include main-font(map-get($sizes, bigger));

  width: 100%;
  text-align: center;
  color: map-get($colors, darkest-gray);
}
