@import '~styles/index.scss';

.mapMarker {
  position: absolute;
  top: -30px;
  right: -30px;
  display: flex;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;

  &Border {
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }

  &Size {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    p {
      @include main-font;

      font-size: map-get($sizes, large);
      color: $color-white;
    }
  }

  &Img {
    position: absolute;
    top: 12px;
    left: 12px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: white;
  }

  &TopSection {
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    height: 24px;
    border: 1px solid $color-white;
    border-radius: 50%;

    img {
      position: absolute;
      width: 22px;
      height: 22px;
      border-radius: 50%;
    }
  }

  &BottomSection {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 24px;
    height: 24px;
    border: 1px solid $color-white;
    border-radius: 50%;

    img {
      position: absolute;
      top: 4px;
      left: 4px;
      width: 14px;
      max-width: 14px;
      max-height: 14px;
      filter: invert(99%) sepia(1%) saturate(0%) hue-rotate(360deg) brightness(106%) contrast(100%);
    }
  }
}

.driverAssigned {
  background: #36af5e26;

  .mapMarkerBorder,
  .mapMarkerTopSection,
  .mapMarkerBottomSection {
    background: #36af5e;
  }
}

.enRouteToCustomer {
  background: #0bcaf926;

  .mapMarkerBorder,
  .mapMarkerTopSection,
  .mapMarkerBottomSection {
    background: #0bcaf9;
  }
}

.arrivedToCustomer {
  background: #fe3e7f26;

  .mapMarkerBorder,
  .mapMarkerTopSection,
  .mapMarkerBottomSection {
    background: #fe3e7f;
  }
}

.enRouteToDealership {
  background: #6b46ff26;

  .mapMarkerBorder,
  .mapMarkerTopSection,
  .mapMarkerBottomSection {
    background: #6b46ff;
  }
}
