@import '~styles/index.scss';

.conciergeApp {
  .menuTemplatePage {
    height: calc(100% - #{$header-height});
    min-height: calc(100vh - #{$header-height});
  }
}

.conciergeApp.withReports {
  .menuTemplatePage {
    height: calc(100% - #{$header-height-with-reports});
    min-height: calc(100vh - #{$header-height-with-reports});
  }
}

.main {
  @include horizontally-centered;

  padding: 16px 0;
}

.rightSideContent {
  display: flex;
  font-family: $ff-ibm;
  align-items: center;

  span {
    margin-right: 5px;
  }

  :global(.conciergeButton) {
    margin-left: 16px;
  }
}

.serviceGroup {
  display: flex;
  margin-bottom: 8px;
  font-family: $ff-ibm;
  font-size: map-get($sizes, big);
  letter-spacing: 0.15px;
  line-height: 46px;
  color: map-get($colors, main-black);
  align-items: center;
  justify-content: space-between;

  .buttons {
    display: flex;

    :global(.conciergeButton) {
      margin-left: 16px;
    }
  }

  .addButtonBrand {
    width: 250px;
  }
}

.itemsContainer {
  :global(.conciergePanel) {
    margin-bottom: 8px;
  }
}

.isLoading {
  display: flex;
  justify-content: center;
}

.goBackButton {
  display: flex;
  text-decoration: none;
  color: $color-black;
  background-color: inherit;
  align-items: baseline;
  cursor: pointer;

  h2 {
    @include secondary-font(map-get($sizes, very-big));
  }

  img {
    position: relative;
    bottom: -3px;
  }
}
