@import '~styles/index.scss';

.chooseServices {
  &Container {
    display: flex;
    flex-direction: column;
  }

  &Togglers {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;
  }
}

.loading {
  @include main-font(map-get($sizes, bigger));

  width: 100%;
  text-align: center;
  color: map-get($colors, darkest-gray);
}

.submit {
  display: flex;
  margin: 20px 0 0;
  justify-content: flex-end;
}
