@import "~styles/index.scss";

.container {
  display: flex;
  padding: 7px 12px;
  align-items: center;

  &:first-child {
    padding-top: 12px;
  }

  &:last-child {
    padding-bottom: 12px;
  }

  span {
    font-size: map-get($sizes, smaller);
    color: map-get($colors, bluey-grey);
  }

  img {
    max-width: 25px;
    max-height: 25px;
    margin-right: 10px;
  }

  :global {
    .enRouteToCustomerFilter {
      filter: invert(66%) sepia(32%) saturate(3374%) hue-rotate(149deg) brightness(100%) contrast(96%);
    }

    .arrivedToCustomerFilter {
      filter: invert(40%) sepia(27%) saturate(4784%) hue-rotate(316deg) brightness(99%) contrast(104%);
    }

    .enRouteToDealershipFilter {
      filter: invert(44%) sepia(100%) saturate(5596%) hue-rotate(243deg) brightness(100%) contrast(103%);
    }
  }
}

.checkbox {
  width: 16px;
  height: 16px;
}
