@import '~styles/index.scss';

.panel {
  :global {
    .conciergePanelBody {
      width: 100%;
      padding: 0;
    }
  }
}

.row {
  display: flex;
  padding: 15px 0 10px 0;
  justify-content: space-between;

  &:first-child {
    padding-top: 25px;
  }

  &:last-child {
    padding-bottom: 0;
  }
}

.createdLabel {
  @include secondary-font(map-get($sizes, small));

  color: map-get($colors, font-gray);
}

.createdTime {
  @include secondary-font(map-get($sizes, small));

  color: map-get($colors, main-black);
}
