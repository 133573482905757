@import '~styles/index.scss';

.container {
  display: flex;

  .enRouteToCustomerFilter {
    filter: invert(66%) sepia(32%) saturate(3374%) hue-rotate(149deg) brightness(100%) contrast(96%);
  }

  .arrivedToCustomerFilter {
    filter: invert(40%) sepia(27%) saturate(4784%) hue-rotate(316deg) brightness(99%) contrast(104%);
  }

  .enRouteToDealershipFilter {
    filter: invert(44%) sepia(100%) saturate(5596%) hue-rotate(243deg) brightness(100%) contrast(103%);
  }
}

.statusCell {
  @include sizing(30px 25px);

  display: flex;
  align-content: center;
  justify-content: center;
}

.alternativeTransport {
  width: 25px;
  height: 25px;
  text-align: center;
  border: null;
  border-radius: 15px;
}

.tooltip {
  font-family: $ff-lato;
  font-size: map-get($sizes, smaller);
  opacity: 1 !important;
}

.canceled {
  font-size: map-get($sizes, regular);
  font-style: $fs-normal;
  font-weight: $fw-normal;
  text-align: center;
  color: map-get($colors, gray);
}
