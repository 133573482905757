@import '~styles/index.scss';

.searchField {
  position: relative;
  display: flex;
  width: 300px;
  height: fit-content;
  padding: 10px;
  border: $default-border;
  border-radius: $small-border-radius;
  justify-content: space-between;

  &FullWidth {
    width: 100%;
  }
}

.input {
  @include main-font;

  width: 100%;
  outline: none;
  border: none;

  &::placeholder {
    color: map-get($colors, font-gray);
  }
}

.icon {
  cursor: pointer;
}

.tooltip {
  @include main-font;

  position: absolute;
  top: 50px;
  right: 0;
  z-index: $z-index-2;
  width: 120px;
  padding: 15px;
  border: $default-border;
  border-radius: $small-border-radius;
  color: map-get($colors, font-gray);
  background-color: map-get($colors, white);
  box-shadow: $default-box-shadow;
}
