@import '~styles/index.scss';

.container {
  @include main-font;

  display: grid;
  width: 100%;
  max-width: 1120px;
  grid-template-areas: "choicePanel details";
  grid-template-columns: minmax(600px, 4fr) 2fr;
  column-gap: 25px;
  row-gap: 25px;
  margin: 36px;
  align-items: start;
}

.choicePanel {
  grid-area: choicePanel;

  :global {
    .carouselArrowsContainer {
      padding-bottom: 10px;
    }
  }
}

.accordion {
  margin-bottom: 20px;

  &Hide {
    display: none;
  }

  &Alert {
    border-color: red;
  }
}

.lastAdvisor {
  margin-top: 35px;
}

.summary {
  grid-area: details;

  :global {
    .accordionContent {
      &.isExpanded {
        padding: 0;
      }
    }
  }
}
