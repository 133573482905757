@import "~styles/index.scss";

.holidays {
  display: flex;
  flex-wrap: wrap;

  &Container {
    width: 100px;
    height: 32px;
    margin-right: 10px;
    margin-bottom: 15px;
    padding: 5px;
    border: 1px solid map-get($colors, darker-gray);
    border-radius: 6px;
  }

  &Text {
    font-family: $ff-lato;
    font-size: $fs-regular;
  }

  &Calendar {
    display: flex;
    justify-content: center;

    .rmdp-day.rmdp-selected span:not(.highlight) {
      background-color: map-get($colors, warning-red);
    }

    .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
      background-color: map-get($colors, darkest-gray);
    }

    .rmdp-month-name {
      margin: 3px 0;
      font-family: $ff-lato;
      font-size: $fs-regular;
    }

    .rmdp-day,
    .rmdp-header-values {
      font-family: $ff-lato;
      font-size: $fs-regular;
    }

    .rmdp-week-day {
      font-family: $ff-lato;
      font-size: $fs-smaller;
      color: map-get($colors, font-gray);
    }

    .rmdp-full-year {
      grid-template-columns: repeat(4, 285px);
    }

    .rmdp-day.rmdp-today span {
      background-color: map-get($colors, light-blue);
    }

    .rmdp-border {
      border: 1px solid map-get($colors, darker-gray);
    }

    .rmdp-day-picker.rmdp-full-year {
      > div {
        margin: 0 15px;
      }
    }
  }

  &Button {
    display: flex;
    margin-left: auto;
    flex-direction: row-reverse;
  }

  &Loading {
    position: absolute;
    top: 0;
    left: 0;
    z-index: $z-index-10;
    display: flex;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    transition: all 0.3s ease;
    align-items: center;
    justify-content: center;
  }
}
