@import '~styles/index.scss';

.QuestionStep {
  &Header {
    display: flex;
    margin-bottom: 4px;
    align-items: center;
    justify-content: space-between;

    &Label {
      @include main-font(map-get($sizes, much-smaller), $fw-bold);

      letter-spacing: 1px;
      line-height: 16px;
      text-transform: uppercase;
      color: map-get($colors, main-black);
    }
  }

  &TextInput {
    width: 100%;
    padding: 14px 16px;
    font-family: $ff-lato;
    font-size: map-get($sizes, regular);
    line-height: 20px;
    border: 1px solid map-get($colors, darker-gray);
    border-radius: 6px;
    color: map-get($colors, main-black);
    background-color: map-get($colors, white);

    &:disabled {
      background: #e9ecef;
    }
  }

  &Body {
    display: flex;
    margin-bottom: 22px;
    padding: 24px;
    line-height: 16px;
    border: 1px solid map-get($colors, darker-gray);
    border-radius: 6px;
    background-color: map-get($colors, white);
    flex-direction: column;

    &Inner {
      display: flex;

      &Left {
        width: 100%;
      }

      &Right {
        width: 350px;
        min-width: 350px;
        margin-left: 20px;
      }
    }

    &Answers {
      display: flex;
      flex-direction: column;

      .QuestionStepHeaderDelete {
        margin-top: 18px;
      }

      .conciergeButton {
        margin-left: 16px;
      }
    }

    &Answer {
      display: flex;
      margin-top: 20px;
    }

    &Column {
      display: flex;
      flex-direction: column;

      &Answer {
        width: 100%;
      }

      &Abbreviation {
        width: 350px;
        min-width: 350px;
        margin-left: 20px;
      }
    }

    &Label {
      @include main-font(map-get($sizes, smaller));

      margin-bottom: 2px;
      line-height: 18px;
      color: map-get($colors, main-black);
    }

    &Input {
      @include main-font;

      width: 100%;
      padding: 14px 16px;
      line-height: 20px;
      border: 1px solid map-get($colors, darker-gray);
      border-radius: 6px;
      color: map-get($colors, main-black);
      background-color: map-get($colors, white);

      &:disabled {
        background: #e9ecef;
      }
    }

    &Select {
      .StyledSelect__control {
        width: 120px;
        min-width: 120px;
        height: 50px;

        &--is-disabled {
          background-color: #e9ecef !important;
        }
      }
    }

    &Row {
      display: flex;
      align-items: center;

      img {
        padding: 0 16px;
      }
    }
  }

  &AddNewButton {
    @include secondary-font(map-get($sizes, slightly-bigger));

    width: 90px;
    margin-top: 24px;
    padding: 0;
    line-height: 16px;
    color: map-get($colors, light-blue);
    background: transparent;
    cursor: pointer;
  }
}
