@import '~styles/index.scss';

.reportsContainer {
  background-color: map-get($colors, main-black);

  &Centering {
    @include horizontally-centered;

    padding: 24px 0;
  }
}

.reportsNavigationContainer {
  display: flex;
  min-height: 40px;
  align-items: center;
  justify-content: space-between;

  .conciergeInputLabel {
    color: $color-white;
  }

  .conciergeInput {
    height: 34px;
    font-size: $fs-small;
  }

  .additionalReportsLink {
    display: flex;
    height: 100%;
    padding: 0;
    padding-left: 16px;
    font-family: $ff-lato;
    font-size: $fs-regular;
    font-weight: $fs-normal;
    text-decoration: none;
    color: $color-white;
    align-items: center;
  }

  .flexContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.reportsData {
  display: flex;
  overflow-x: auto;
  padding-top: 12px;
  padding-bottom: 24px;
  justify-content: space-between;

  &Block {
    &X1:not(:first-child),
    &X2:not(:first-child),
    &X3:not(:first-child),
    &X4:not(:first-child) {
      margin-left: 12px;
      padding-left: 12px;
      border-left: 1px solid $color-gray-04;
    }

    &X1 {
      min-width: 130px;
    }

    &X2 {
      min-width: 200px;
    }

    &X3 {
      min-width: 300px;
    }

    &X4 {
      min-width: 360px;
    }

    &Title {
      font-family: $ff-lato;
      font-size: $fs-small;
      font-style: normal;
      font-weight: $fw-bold;
      line-height: 22px;
      color: $color-white;
    }

    &ItemsContainer {
      display: flex;
      justify-content: space-between;
    }

    &Item {
      &Value {
        display: flex;
        font-family: $ff-lato;
        font-size: $fs-small;
        font-style: normal;
        font-weight: $fw-bold;
        line-height: 22px;
        color: $color-white;

        &AdditionalInfo {
          padding-left: 2px;
          font-family: $ff-lato;
          font-size: $fs-tiny;
          font-style: normal;
          font-weight: $fw-normal;
          line-height: 16px;
          color: $color-gray;
        }
      }

      &Label {
        font-family: $ff-lato;
        font-size: $fs-tiny;
        font-style: normal;
        font-weight: $fw-normal;
        line-height: 16px;
        color: $color-gray;
      }
    }
  }
}
