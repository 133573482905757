@import '~styles/index.scss';

.addMenuItemModal {
  &Label {
    margin-bottom: 4px;
    font-family: $ff-lato;
    font-size: map-get($sizes, smaller);
    line-height: 18px;
    color: map-get($colors, main-black);

    &TopMargin {
      margin-top: 10px;
    }
  }
}

.addMenuItemSelect,
.salesEmailsSelect {
  &__control {
    height: 48px;
    font-family: $ff-lato;
    font-size: map-get($sizes, regular);
    line-height: 20px;
    border: 1px solid map-get($colors, darker-gray);
    border-radius: 6px;
    color: map-get($colors, main-black);
    background-color: map-get($colors, white);
  }

  &__menu {
    display: flex;
    height: 180px;
    padding: 8px 0;
    font-family: $ff-lato;
    font-size: map-get($sizes, regular);
    line-height: 20px;
    border: 1px solid map-get($colors, darker-gray);
    border-radius: 10px;
    background-color: map-get($colors, white);
    box-shadow: 0 12px 30px 0 rgba(map-get($colors, black), 0.16);
    flex-direction: column;

    .menuHidden & {
      display: none;
    }

    &-list {
      padding: 0;

      .addMenuItemSelect__option {
        display: flex;
        height: auto;
        min-height: 44px;
        font-family: $ff-lato;
        font-size: map-get($sizes, regular);
        line-height: 20px;
        border: none;
        color: map-get($colors, main-black);
        align-items: center;

        &--is-selected {
          background-color: rgba(map-get($colors, light-blue), 0.1);
        }

        &--is-focused {
          background-color: rgba(map-get($colors, light-blue), 0.05);
        }
      }
    }
  }
}

.salesEmailsSelect {
  &Wrapper {
    position: relative;
    margin-bottom: 25px;
  }

  &Label {
    margin-bottom: 3px;
    font-family: $ff-lato;
    font-size: map-get($sizes, smaller);
  }

  &Error {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    margin-top: 5px;
    text-align: center;
    color: map-get($colors, warning-red);
  }

  &__control,
  .conciergeSettingsPage &__control {
    height: 100%;
    min-height: 44px;
  }
}

.editMenuItemModal {
  height: 550px;
}
