@import '~styles/index.scss';

.editDropOffJobModal {
  @include main-font;

  width: 680px;

  :global(.conciergeModalBody) {
    overflow-x: hidden;
  }

  .marginBottom {
    margin-bottom: 16px;
  }

  .alertContainer {
    min-height: 64px;
  }

  .calendarContainer {
    display: flex;
    min-height: 320px;
    margin-top: 16px;
    align-items: center;
    justify-content: center;
  }

  .spinnerContainer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    background-color: rgba(249, 250, 252, 0.6);
    align-items: center;
    justify-content: center;
  }

  .ignorSwitchContainer {
    display: flex;
    margin-top: 16px;
    align-items: center;

    .switch {
      margin-left: 15px;
    }
  }
}
