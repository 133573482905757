@import "~styles/index.scss";

.wrapper {
  @include main-font;

  display: flex;
  margin-right: 1rem;
  margin-left: 0;
  flex-direction: column;

  .label {
    margin-bottom: 5px;
    line-height: 20px;
    color: map-get($colors, bluey-grey);
  }
}

.filter {
  position: relative;
  display: flex;
  height: 44px;
  margin: 3px 0;
  border-radius: 6px;
  background: map-get($colors, white);
  flex-direction: row;
  user-select: none;

  > div {
    &:first-child {
      div {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }
    }

    &:last-child {
      div {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }
}
