@import '~styles/index.scss';

.employeeListContainer {
  display: flex;
}

.employeeListItemsContainer {
  display: flex;
  overflow-x: auto;
  padding-bottom: 10px;
}

.employeeCard {
  display: flex;
  width: 114px;
  height: 114px;
  margin-right: 12px;
  background-color: inherit;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.employeeCardWithSeparator {
  margin-right: 6px;
  padding-right: 20px;
  border-width: 1px;
  border-style: solid;
  border-left: 0;
  border-image: linear-gradient($color-white, #9ba4b585, $color-white) 0 100%;
}

.employeeCardImageContainer {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 84px;
  min-width: 84px;
  height: 84px;
  min-height: 84px;
  padding: 6px;
  border: 2px solid #dedee0;
  border-radius: 100%;
  background: rgba(11, 202, 249, 0.05);
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.employeeCardIcon {
  width: 30px;
  height: 30px;
}

.employeeCardImage {
  width: 72px;
  height: 72px;
  border-radius: 100%;
}

.employeeCardStatus {
  position: absolute;
  top: 57px;
  left: 57px;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  border: 1px solid #dedee0;
  border-radius: 100%;
  background: #fff;
}

.employeeCardStatusIsChecked {
  position: absolute;
  top: 57px;
  left: 57px;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
}

.employeeName {
  overflow: hidden;
  width: 114px;
  height: 114px;
  font-family: $ff-lato;
  font-size: $fs-small;
  font-style: normal;
  font-weight: $fw-bold;
  line-height: 30px;
  text-align: center;
  white-space: nowrap;
  color: $color-gray;
  text-overflow: ellipsis;
}

.employeeCardIsActive {
  .employeeCardImageContainer {
    border: 2px solid $color-aqua;
  }

  .employeeCardStatus {
    border: 1px solid $color-aqua;
    border-radius: 100%;
    background: $color-aqua;
  }

  .employeeName {
    color: $color-aqua;
  }
}
