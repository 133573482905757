@import '~styles/index.scss';

.editModal {
  &Label {
    margin-bottom: 4px;
    font-family: $ff-lato;
    font-size: map-get($sizes, smaller);
    line-height: 18px;
    color: map-get($colors, main-black);

    &TopMargin {
      margin-top: 10px;
    }
  }

  &Input {
    @include sizing(100% 48px);

    margin-top: 4px;
    padding: 14px 16px;
    font-family: $ff-lato;
    font-size: map-get($sizes, regular);
    line-height: 20px;
    border: 1px solid map-get($colors, darker-gray);
    border-radius: 6px;
    color: map-get($colors, main-black);
    background-color: map-get($colors, white);
  }
}

.menuTemplateEditModal {
  height: 550px;
}
