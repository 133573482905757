@import '~styles/index.scss';

.menuItemDetails {
  &Section {
    margin-bottom: 12px;

    &Header {
      display: flex;
      height: 48px;
      margin-bottom: 16px;
      align-items: center;
      justify-content: space-between;

      .conciergeButtonBrand {
        width: 180px;
      }
    }

    &Label {
      display: flex;
      font-family: $ff-lato;
      font-size: $fs-much-bigger;
      font-weight: $fw-bold;
      line-height: 20px;
      color: map-get($colors, main-black);
    }
  }

  &AddVehicleGroup {
    @include sizing(150px 48px);

    margin-left: auto;
    font-family: $ff-ibm;
    font-size: map-get($sizes, slightly-bigger);
    line-height: 16px;
    border-radius: 6px;
    color: map-get($colors, white);
    background-color: map-get($colors, blueish-gray);
  }

  &Extras {
    display: flex;
    margin-bottom: 16px;
    border: 1px solid map-get($colors, darker-gray);
    border-radius: 6px;
    background-color: map-get($colors, white);
    flex-direction: row;
    justify-content: flex-start;

    &Block {
      width: 100%;
      padding: 24px;
    }
  }
}
