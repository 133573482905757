@import '~styles/index.scss';

.conciergeVehicleDetailsBlock {
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0 25px;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  &Image {
    max-width: 200px;
    max-height: 150px;
  }

  &NoImageText {
    padding: 50px 20px;
  }

  &ModelName {
    padding-top: 4px;
    font-size: map-get($sizes, slightly-bigger);
    font-weight: $fw-bold;
    color: map-get($colors, main-black);
  }

  &BookingDetails {
    padding-top: 4px;
    font-size: map-get($sizes, regular);
    color: map-get($colors, font-gray);
  }

  &Group {
    display: flex;
    height: 110px;
    margin: 0 25px;
    flex-flow: column nowrap;
    justify-content: space-around;

    &Input {
      padding-top: 4px;
      font-size: map-get($sizes, smaller);
      font-weight: $fw-normal;
      color: map-get($colors, font-gray);

      .conciergeInput {
        font-size: map-get($sizes, smaller);
      }
    }
  }

  &Label {
    padding-top: 4px;
    font-size: map-get($sizes, smaller);
    font-weight: $fw-normal;
    color: map-get($colors, font-gray);
  }

  &Value {
    padding-top: 4px;
    font-size: map-get($sizes, small);
    font-weight: $fw-bold;
    color: map-get($colors, main-black);
  }
}
