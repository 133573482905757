@import '~styles/index.scss';

.conciergeCalendar {
  &Navigation {
    display: flex;
    margin-bottom: 14px;
    font-family: $ff-lato;
    align-items: center;
    justify-content: space-between;

    .conciergeInputWrapper {
      margin: 0;
    }

    .conciergeButton {
      border: 1px solid #e7e7e8;
      border-radius: 4px;
    }
  }

  &MonthYearWrapper {
    display: flex;
    align-items: center;
  }

  &MonthName {
    margin-right: 10px;
  }

  &Year {
    width: 66px !important;
    height: 36px !important;
    font-size: $fs-small !important;
  }

  &Icon {
    width: 10px;
    height: 10px;
    cursor: pointer;

    &.prevMonth {
      border-top: $default-border;
      border-left: $default-border;
      transform: rotate(-45deg);
    }

    &.nextMonth {
      border-top: $default-border;
      border-right: $default-border;
      transform: rotate(45deg);
    }
  }

  &WeekDaysRow {
    display: flex;
  }

  &WeekDay {
    width: 40px;
    font-family: $ff-lato;
    font-size: $fs-smaller;
    text-align: center;
    color: $color-gray;
  }

  &DaysRow {
    display: flex;
  }
}
