@import '~styles/index.scss';

.page {
  height: auto;
  min-height: 100vh;
}

.header {
  @include main-font(map-get($sizes, slightly-smaller), $fw-bold);
}

.container {
  @include main-font;

  display: grid;
  width: 100%;
  max-width: 1120px;
  grid-template-areas: "choicePanel details";
  grid-template-columns: minmax(600px, 4fr) 2fr;
  column-gap: 25px;
  row-gap: 25px;
  margin: 36px auto;
  align-items: start;

  .hide {
    display: none;
  }
}

.accordion {
  margin-bottom: 20px;
}

.choice {
  grid-area: choicePanel;

  .carouselArrowsContainer {
    padding-bottom: 10px;
  }
}

.lastAdvisor {
  margin-top: 35px;
}

.detailsAccordion {
  grid-area: details;

  :global {
    .accordionContent {
      &.isExpanded {
        padding: 0;
      }
    }
  }
}

.bar {
  max-width: 1120px;
  margin: 31px auto 0;
  padding: 8px 0;
  font-family: $ff-lato;
  text-align: center;
  border-radius: 10px;
  color: map-get($colors, white);
  background-color: map-get($colors, red);
  box-shadow: 0 12px 30px 0 rgba(0, 0, 0, 0.16);
}

.borderColorRed {
  border-color: red;
}

.button {
  @include main-font;

  display: flex;
  width: 100%;
  max-width: 1120px;
  margin: auto;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;

  button {
    margin: 20px 0;
  }
}
