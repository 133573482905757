@import "~styles/index.scss";

.accordion {
  margin-bottom: 20px;

  p {
    @include main-font;
  }

  :global(.accordion) {
    margin-bottom: 20px;
  }
}

.container {
  margin-bottom: 25px;
}

.switch {
  display: flex;
  width: 48%;
  align-items: center;

  .label {
    font-family: $ff-lato;
    font-size: $fs-smaller;
  }

  .toggle {
    margin-left: 10px;
  }
}

.addButton {
  display: flex;
  justify-content: flex-end;
}

.dropdownContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.inputsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  :global(.conciergeInputWrapper) {
    width: 48%;
  }
}

.datesContainer {
  display: flex;
  justify-content: space-between;

  :global(.conciergeInputWrapper) {
    width: 48%;
  }
}

.dropdown {
  width: 48%;
  margin-top: 5px;
}

.make {
  margin-bottom: 25px;

  .label {
    font-family: $ff-lato;
    font-size: $fs-smaller;
  }

  :global(.multiTagSelectMenu) {
    height: 150px;
  }
}

.loader {
  display: block;
  padding: 0 100px;
  text-align: center;
}
