@import '~styles/index.scss';

.chooseServicesContainer {
  overflow: auto;
  max-height: 1000px;
  margin-top: 16px;
  padding: 0 10px;
}

.loadingPanel {
  @include main-font(map-get($sizes, bigger));

  width: 100%;
  margin-top: 16px;
  text-align: center;
  color: map-get($colors, darkest-gray);
}
