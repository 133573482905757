@import '~styles/index.scss';

.editEmployeeModal {
  .conciergeAlert {
    margin-bottom: 16px;
  }

  .conciergeBlockContent {
    padding: 24px;
    background-color: #f9fafc;

    .conciergeInputWrapper {
      margin-right: 16px;
    }
  }

  &DaySelector {
    display: flex;

    button:not(:first-child) {
      margin-left: 8px;
    }
  }

  &ConciergePanel {
    margin-bottom: 0 !important;
    border-radius: 5px;

    hr {
      width: 16px;
      margin: 0 16px;
      border: 0.5px solid grey;
    }

    input[type="checkbox"] {
      width: 18px;
      height: 18px;
      border: 1px solid #dedee0;
      border-radius: 3px;
      background: #fff;
    }

    label {
      margin-left: 6px;
    }
  }

  &TimeSelectSection {
    &:not(:first-child) {
      margin-top: 16px;
    }

    .timeField {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      &Label {
        padding: 0.2rem;
      }

      &Block {
        @include flex-centered;
      }

      &Input {
        display: flex;
        width: 90px;
        height: 44px;
        margin: 3px;
        border: $default-border;
        border-radius: $small-border-radius;
        background-color: map-get($colors, white);

        &:disabled {
          color: map-get($colors, font-gray);
          background-color: map-get($colors, white);
        }

        .react-time-picker__wrapper {
          padding: 0 8px;
          border: none;

          .react-time-picker__inputGroup__amPm {
            border: none;
            appearance: none;
          }
        }

        .react-time-picker__button {
          padding: 5px 0;
        }
      }
    }
  }

  &DayOff {
    display: flex;
    margin-left: 16px;
    color: map-get($colors, grey);
    align-items: center;
  }

  &SplitTime {
    color: $color-aqua;
  }

  &PrinterSettingsContainer {
    display: flex;
    flex-direction: row;

    .conciergeInputWrapper:last-child {
      margin-left: 20px;
    }
  }
}

.capacitySettings {
  &EditEmployeeModal {
    &Outer {
      position: fixed;
      top: 0;
      left: 0;
      z-index: $z-index-2;
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
    }

    &PhotoBlock {
      .conciergeBlockContent {
        display: flex;
        min-height: 120px;
        align-items: center;

        .conciergeImageInputLabel {
          min-width: auto;
          margin: 0;
        }
      }
    }

    &SettingsContainer {
      display: flex;
      justify-content: space-between;
    }

    &Availability {
      &Title {
        margin-bottom: 10px;
        font-family: $ff-lato;
        font-size: map-get($sizes, much-smaller);
        font-style: $fs-normal;
        font-weight: $fw-bold;
        line-height: map-get($sizes, slightly-bigger);
        text-transform: uppercase;
        color: map-get($colors, grey);
      }

      &Option {
        width: 136px;
        height: 46px;
        outline: none;
        border: 1px solid map-get($colors, darker-gray);
        background-color: map-get($colors, white);
        cursor: pointer;

        &:first-child {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }

        &:nth-child(2) {
          position: relative;
          border-right: none;
          border-left: none;

          &.availabilityOptionActive &::before,
          &.availabilityOptionActive &::after {
            position: absolute;
            top: 0;
            left: 100%;
            width: 1px;
            height: 100%;
            content: "";
            background-color: map-get($colors, light-blue);
          }

          &.availabilityOptionActive &::after {
            right: 100%;
            left: inherit;
          }
        }

        &:last-child {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }

        &.availabilityOptionActive {
          border: 1px solid map-get($colors, light-blue);
          background: linear-gradient(0deg, rgba(11, 202, 249, 0.05), rgba(11, 202, 249, 0.05)), #fff;
          box-shadow: 0 4px 10px rgba(27, 43, 41, 0.08);
        }
      }
    }

    &Separator {
      width: 1px;
      height: 72px;
      margin: 0 32px;
      background-color: map-get($colors, darker-gray);
    }

    & .multiTagListOuter {
      max-width: 274px;
      flex: 1;
    }

    &Label {
      font-family: $ff-lato;
      font-size: map-get($sizes, smaller);
      line-height: 18px;
      color: map-get($colors, main-black);
    }

    &Input {
      @include sizing(100% 48px);

      margin-top: 4px;
      padding: 14px 16px;
      font-family: $ff-lato;
      font-size: map-get($sizes, regular);
      line-height: 20px;
      border: 1px solid map-get($colors, darker-gray);
      border-radius: 6px;
      color: map-get($colors, main-black);
      background-color: map-get($colors, white);
    }

    &StatusBar {
      max-width: auto;
      margin: 0 auto;
      padding: 8px 0;
      font-family: $ff-lato;
      font-size: map-get($sizes, regular);
      line-height: 20px;
      text-align: center;
      border-radius: 10px;
      color: map-get($colors, white);
      box-shadow: 0 12px 30px 0 rgba(0, 0, 0, 0.16);
      cursor: pointer;

      &Success {
        background-color: map-get($colors, green2);
      }

      &Error {
        background-color: map-get($colors, red);
      }
    }

    &HoursBlock {
      display: flex;
      width: 320px;
      justify-content: space-between;
    }

    &HoursSelect {
      width: 150px;
    }

    &WorkingHoursBlock {
      width: 700px;
      margin: 25px 0 0;

      &Content {
        display: flex;
        height: 330px;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: space-around;
      }
    }
  }
}
