@import '~styles/index.scss';

.page {
  height: calc(100% - 56px);
  min-height: calc(100vh - 56px);

  :global {
    .conciergePageHeaderTitle {
      display: flex;
      width: 100%;
      align-items: center;
    }

    .conciergePanel {
      width: 100%;
      margin: 35px 0;
    }

    .conciergePanelSidebar {
      width: 100%;
      margin: 35px 0;
    }
  }
}

.body {
  @include horizontally-centered;
}

.container {
  @include main-font;

  display: grid;
  width: 100%;
  max-width: 1120px;
  grid-template-areas: "choicePanel details";
  grid-template-columns: minmax(600px, 4fr) 2fr;
  column-gap: 25px;
  row-gap: 25px;
  margin: auto;
  align-items: start;
}

.loadingPanel {
  @include main-font(map-get($sizes, bigger));

  width: 100%;
  text-align: center;
  color: map-get($colors, pink);
}

.choicePanel {
  grid-area: choicePanel;

  :global {
    .carouselArrowsContainer {
      padding-bottom: 10px;
    }

    .accordion {
      margin-bottom: 16px;
    }
  }
}

.label {
  @include main-font(map-get($sizes, smaller));

  margin-bottom: 10px;
  color: map-get($colors, font-gray);
}

.value {
  @include main-font;

  text-transform: capitalize;

  &-notTransformed {
    text-transform: none;
  }
}

.sidebarButtons {
  button:not(:first-child) {
    margin-top: 16px;
  }
}

.buttonsWrap {
  display: flex;
  margin-left: auto;
  align-items: center;
  justify-content: space-between;

  button:not(:first-child) {
    margin-left: 16px;
  }
}

.buttonsLoading {
  display: block;
  width: 26px;
  height: 26px;
  margin-right: 50px;
  line-height: 1;
}

.goBackButton {
  @include main-font;

  display: flex;
  text-decoration: none;
  color: $color-black;
  background-color: inherit;
  align-items: baseline;
  cursor: pointer;

  img {
    position: relative;
    bottom: -3px;
  }
}

.dataContainer {
  display: flex;
  margin: 10px;
  margin-bottom: 0;
  flex-wrap: wrap;

  &-justifyEnd {
    justify-content: flex-end;
  }
}

.bookingReassign {
  &Container {
    @include main-font;

    display: flex;
    margin-top: 16px;
    flex-direction: column;
    align-items: center;
  }

  .buttonContainer {
    display: flex;
    margin-top: 16px;

    button:not(:first-child) {
      margin-left: 8px;
    }
  }
}

.labelNotShown {
  @include main-font(map-get($sizes, regular));

  padding-right: 20px;
  color: map-get($colors, font-gray);
}

.detailsBlock {
  & + & {
    margin-top: 16px;
    padding-top: 16px;
    border-top: 0.5px solid map-get($colors, font-gray);
  }

  &Item {
    display: flex;
    flex: 1;
    flex-flow: column nowrap;
    justify-content: center;
  }

  &Group {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;

    & + & {
      margin-top: 10px;
    }
  }

  &Label {
    padding-top: 4px;
    font-size: map-get($sizes, smaller);
    font-weight: $fw-normal;
    color: map-get($colors, font-gray);
  }

  &Value {
    padding-top: 4px;
    font-size: map-get($sizes, small);
    font-weight: $fw-bold;
    color: map-get($colors, main-black);
  }
}

.statusBlock {
  display: flex;
  justify-content: space-between;

  .statusTime {
    font-size: map-get($sizes, smaller);
    font-weight: $fw-normal;
    color: map-get($colors, font-gray);
  }
}

.questionariesAnswers {
  display: flex;
  flex-wrap: wrap;

  .detailsBlock {
    width: 50%;
  }

  .detailsBlock:nth-of-type(2) {
    margin-top: 0;
    padding-top: 0;
    border-top: none;
  }

  .detailsBlock:nth-of-type(odd) {
    padding-right: 4px;
  }

  .detailsBlock:nth-of-type(even) {
    padding-left: 4px;
  }
}

.canceledBlock {
  display: flex;
  margin-left: auto;
  padding-right: 20px;
  flex-direction: column;

  &Label {
    @include main-font(map-get($sizes, regular));

    color: map-get($colors, font-gray);
  }

  &Text {
    @include main-font(map-get($sizes, smaller));

    color: map-get($colors, font-gray);
  }
}

.questionariesSource {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 0.5px solid #a4acbc;

  .sourceLabel {
    color: map-get($colors, font-gray);
  }
}

.halfPart {
  width: 50%;
}

.selectedOption {
  @include main-font(map-get($sizes, regular));

  margin-right: 5px;
  margin-left: auto;
  color: map-get($colors, font-gray);
}

.panel {
  :global {
    .conciergeBlockContent {
      background-color: map-get($colors, light-gray);
    }

    .conciergePanelBody {
      display: flex;
      padding: 24px;
      flex-direction: column;
    }

    .conciergePanelHeader {
      display: flex;
      padding: 21px 24px;
      font-family: $ff-lato;
      font-size: $fs-bigger;
      align-items: center;
      justify-content: space-between;
    }
  }
}
