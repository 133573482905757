@import '~styles/index.scss';

.driversSelection {
  &Container {
    flex: 1;
  }

  &Label {
    display: flex;
    margin: 16px 0;
    font-family: $ff-lato;
    font-size: map-get($sizes, regular);
    font-weight: $fw-bold;
    line-height: 20px;
    align-items: center;
    justify-content: center;
  }
}
