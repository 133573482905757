@import '~styles/index.scss';

.conciergeInput {
  @include main-font(map-get($sizes, slightly-bigger));

  display: block;
  width: 100%;
  height: 44px;
  margin: 3px 0;
  padding-left: 15px;
  outline: none;
  border: 1px solid map-get($colors, darker-gray);
  border-radius: 6px;

  &:disabled {
    color: map-get($colors, font-gray);
    background-color: map-get($colors, white);
  }

  &.error {
    border-color: map-get($colors, pink);
  }

  &Wrapper {
    @include main-font;

    margin-top: 5px;
    margin-bottom: 20px;
  }

  &Label {
    @include main-font(map-get($sizes, smaller));
  }

  &Error {
    @include main-font(map-get($sizes, smaller));

    margin: 0;
    color: map-get($colors, pink);
  }
}
