@import "~styles/index.scss";

.generalBlock {
  margin: 0;
  flex: 1;
}

.timeBlock {
  max-width: 310px;
  margin: 0;
}

.panel {
  :global {
    .conciergePanelHeader {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .conciergePanelBody {
      display: flex;
      padding: 25px;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .conciergeBlockContent {
      background-color: #f9fafc;
    }
  }
}

.addressError {
  @include main-font(map-get($sizes, smaller));

  color: map-get($colors, red);
}

.centralContainer {
  display: flex;
  padding: 0 24px;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}

.timeField {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  &Label {
    padding: 0.2rem;
  }

  &Block {
    @include flex-centered;
  }

  &Input {
    display: flex;
    width: 130px;
    height: 44px;
    margin: 3px;
    border: $default-border;
    border-radius: $small-border-radius;
    background-color: map-get($colors, white);

    &:disabled {
      color: map-get($colors, font-gray);
      background-color: map-get($colors, white);
    }

    :global {
      .react-time-picker__wrapper {
        padding: 0 8px;
        border: none;

        .react-time-picker__inputGroup__amPm {
          border: none;
          appearance: none;
        }
      }

      .react-time-picker__button {
        padding: 5px 0;
      }
    }
  }
}

.input {
  margin-top: 5px;
  margin-bottom: 20px;
}

.switch {
  display: flex;
  align-items: center;

  label {
    @include main-font(map-get($sizes, smaller));

    margin-right: 15px;
  }
}

.name {
  margin-top: 20px;
}

:global {
  .notification-message {
    font-family: $ff-lato;

    .title {
      font-size: map-get($sizes, slightly-bigger);
    }

    .message {
      font-size: map-get($sizes, smaller);
      font-weight: $fw-normal;
    }
  }
}

.select {
  :global(.StyledSelect__menu) {
    height: 150px;
  }
}
