@import '~styles/index.scss';

.select {
  :global(.select) {
    &__control {
      height: 48px;
      margin: 3px 0;
      font-family: $ff-lato;
      font-size: $fs-regular;
      line-height: 20px;
      border: 1px solid #dedee0;
      border-radius: 6px;
      color: $color-black;
      background-color: #fff;
    }

    &__menu {
      display: flex;
      height: 150px;
      padding: 8px 0;
      font-family: $ff-lato;
      font-size: map-get($sizes, regular);
      line-height: 20px;
      border: 1px solid map-get($colors, darker-gray);
      border-radius: 10px;
      background-color: map-get($colors, white);
      box-shadow: 0 12px 30px 0 rgba(map-get($colors, black), 0.16);
      flex-direction: column;

      .menuHidden & {
        display: none;
      }

      &-list {
        padding: 0;

        .select__option {
          display: flex;
          height: auto;
          min-height: 44px;
          font-family: $ff-lato;
          font-size: map-get($sizes, regular);
          line-height: 20px;
          border: none;
          color: map-get($colors, main-black);
          align-items: center;

          &--is-selected {
            background-color: rgba(map-get($colors, light-blue), 0.1);
          }

          &--is-focused {
            background-color: rgba(map-get($colors, light-blue), 0.05);
          }
        }
      }
    }
  }

  &Error {
    :global(.select) {
      &__control {
        border: 1px solid map-get($colors, warning-red);
        box-shadow: none;
      }
    }
  }
}

.roleError {
  font-family: $ff-lato;
  font-size: map-get($sizes, smaller);
  color: $color-warning-red;
}
