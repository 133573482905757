@import '~styles/index.scss';

.container {
  margin-top: 20px;
}

.title {
  position: relative;
  display: flex;
  height: 25px;
  padding: 6px 0;
  font-family: $ff-lato;
  font-size: map-get($sizes, smaller);
  color: map-get($colors, font-gray);
  background-color: map-get($colors, light-gray);
  align-items: center;

  &Icon {
    height: 16px;
    margin-left: 5px;
  }

  &::before,
  &::after {
    position: absolute;
    top: 0;
    right: 100%;
    width: 100px;
    height: 100%;
    padding-right: 50px;
    content: "";
    background-color: map-get($colors, light-gray);
  }

  &::after {
    right: auto;
    left: 100%;
  }
}

.includedTitle {
  position: relative;
  display: flex;
  padding: 6px 0;
  font-family: $ff-lato;
  font-size: map-get($sizes, smaller);
  color: map-get($colors, font-gray);
}

.list {
  padding: 8px 0;
}

.listItem {
  display: block;
  font-size: map-get($sizes, smaller);
}
