@import "~styles/index.scss";

.dropdown {
  position: relative;
  display: flex;
  width: 50px;
  height: 100%;
  margin-left: -1px;
  border: 1px solid map-get($colors, darker-gray);
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .caret {
    position: absolute;
    top: calc(100% - 10px);
    left: calc(50% - 4px);
    width: 8px;
    height: 8px;

    &Open {
      transform: rotate(180deg);
    }
  }

  .circle {
    width: 24px;
    height: 24px;
  }

  &Active {
    z-index: $z-index-3;
    border: 1px solid map-get($colors, mint) !important;
    background: map-get($colors, mint005) !important;
  }
}

.label {
  font-size: map-get($sizes, slightly-smaller);
  color: map-get($colors, gray);
}

.checkbox {
  display: flex;
  align-items: center;

  img {
    width: 16px;
    height: 16px;
    margin-right: 12px;
  }
}

.content {
  position: absolute;
  top: calc(100% + 10px);
  z-index: $z-index-2;
  width: 250px;
  border: 1px solid map-get($colors, darker-gray);
  border-radius: 6px;
  background: map-get($colors, white);
  box-shadow: 0 4px 10px 0 rgba(27, 43, 41, 0.08);

  &Head {
    display: flex;
    padding: 12px;
    justify-content: space-between;
  }

  hr {
    height: 1px;
    margin: 0 12px;
    border: none;
    background-color: map-get($colors, white-gray);
  }
}
