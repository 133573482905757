@import '~styles/index.scss';

.container {
  width: 100%;
  height: 100%;
  padding: 15px 0 25px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: map-get($colors, light-gray);
}

.item {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}

.row {
  display: flex;
  margin: 0 25px;
  flex-flow: row nowrap;
  justify-content: space-between;

  & + & {
    margin-top: 10px;
  }
}

.label {
  padding-top: 4px;
  font-size: map-get($sizes, smaller);
  font-weight: $fw-normal;
  color: map-get($colors, font-gray);
}

.value {
  padding-top: 4px;
  font-size: map-get($sizes, small);
  font-weight: $fw-bold;
  color: map-get($colors, main-black);
}

.note {
  margin-top: 6px;
  padding: 6px;
  border-radius: 6px;
  background-color: $color-secondary-mint-50;
}
