@import "~styles/index.scss";

.conciergeApp {
  .conciergeBookingPage {
    height: calc(100% - #{$header-height});
    min-height: calc(100vh - #{$header-height});
  }
}

.conciergeApp.withReports {
  .conciergeBookingPage {
    height: calc(100% - #{$header-height-with-reports});
    min-height: calc(100vh - #{$header-height-with-reports});
  }
}

.conciergeBookingPage {
  &Main {
    @include horizontally-centered;

    padding: 16px 0;
  }

  .ReactTable.booking {
    border-radius: 0;
  }

  &Filters {
    display: flex;
    margin-bottom: 18px;
    justify-content: space-between;
  }

  &ImportButtonContainer {
    display: flex;
    margin-top: 18px;
    justify-content: flex-end;
  }

  .row {
    display: flex;
    flex-direction: row;
  }

  &RightSideContent {
    display: flex;
    align-items: center;
  }

  &LoadingPanel {
    @include main-font(map-get($sizes, bigger));

    width: 100%;
    text-align: center;
    color: map-get($colors, pink);
  }
}
