.ReactTable {
  border: $default-border;
  border-radius: $small-border-radius;
  background-color: map-get($colors, white);

  .rt-tr {
    display: flex;
    align-items: center;
  }

  .rt-tbody {
    .rt-td {
      @include main-font(map-get($sizes, smaller));

      overflow: visible;
      padding: 20px 18px;
      border-right: none;
    }

    .rt-tr {
      &-group {
        border-bottom: $default-border;
      }

      &.-even:not(.conciergeTableRowLinkBlue):not(.conciergeTableRowLinkGreen):not(.conciergeTableRowLinkYellow) {
        background-color: map-get($colors, light-gray);
      }
    }
  }

  .conciergeTableRowLink {
    cursor: pointer;

    &Blue {
      background-color: map-get($colors, pale-blue);
    }

    &Green {
      background-color: map-get($colors, pale-green);
    }

    &Yellow {
      background-color: map-get($colors, pale-orange);
    }

    &:hover {
      box-shadow: inset $default-box-shadow;
    }

    &:active {
      box-shadow: none;
    }
  }

  &.booking {
    .rt-tbody .rt-td {
      padding: 10px 25px;
      font-size: map-get($sizes, regular);
      font-weight: $fw-bold;
      line-height: 1.53;
      color: map-get($colors, dark-gray);
      justify-content: center;
    }

    .rt-td:last-child {
      padding: 0;
    }

    .conciergeTableRowLink {
      align-items: center;
    }
  }

  .rt-thead {
    &.-header {
      border-bottom: $default-border;
      box-shadow: $default-box-shadow;
    }

    .rt-tr {
      text-align: left;
    }

    .rt-th {
      @include main-font(map-get($sizes, much-smaller), $fw-bold);

      padding: 16px 25px;
      letter-spacing: 1px;
      outline: none;
      text-transform: uppercase;
      border-right: none;
      color: map-get($colors, font-gray);

      &.-sort-desc {
        box-shadow: inset 0 -3px 0 0 map-get($colors, light-blue);
      }

      &.-sort-asc {
        box-shadow: inset 0 3px 0 0 map-get($colors, light-blue);
      }
    }
  }

  .-pagination {
    padding: 15px;
    border-top: $default-border;
    box-shadow: none;

    .-previous,
    .-center,
    .-next {
      flex: none;
    }

    .-pageSizeOptions {
      @include main-font;
    }
  }

  .rt-noData {
    @include main-font;

    top: 40%;
    background: transparent;
  }

  .-loading {
    @include main-font;
  }
}
