@import '~styles/index.scss';

.conciergeDayCell {
  display: flex;
  width: 40px;
  height: 40px;
  font-family: $ff-lato;
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.today {
    color: map-get($colors, light-blue);
  }

  &.withinRange {
    color: map-get($colors, white);
    background-color: lighten(map-get($colors, light-blue), 15%);
  }

  &.selected {
    border-radius: $big-border-radius;
    color: map-get($colors, white);
    background-color: map-get($colors, light-blue);
  }

  &.selected.endOfRange {
    border-radius: 0;
    border-top-right-radius: $big-border-radius;
    border-bottom-right-radius: $big-border-radius;
  }

  &.selected.startOfRange {
    border-radius: 0;
    border-top-left-radius: $big-border-radius;
    border-bottom-left-radius: $big-border-radius;
  }

  &.outOfMonth {
    color: map-get($colors, font-gray);
    cursor: not-allowed;

    &.startOfRange,
    &.endOfRange {
      background-color: lighten(map-get($colors, light-blue), 15%);
    }
  }
}

.conciergeDatepickerDarkMode {
  .conciergeDayCell {
    &.today {
      color: $color-black;
    }

    &.withinRange {
      color: $color-black;
      background-color: #0a0c160d;
    }

    &.selected {
      border-radius: 4px;
      color: map-get($colors, white);
      background-color: #0a0c16;
    }

    &.outOfMonth {
      &.startOfRange,
      &.endOfRange {
        color: map-get($colors, white);
        background-color: #0a0c16;
      }
    }
  }
}
