@import '~styles/index.scss';

.multiTag {
  &List {
    min-height: 44px;
    padding: 5px;
    font-family: $ff-lato;

    &Label {
      margin-bottom: 10px;
      font-family: $ff-lato;
      font-size: map-get($sizes, much-smaller);
      font-style: $fs-normal;
      font-weight: $fw-bold;
      line-height: 16px;
      text-transform: uppercase;
      color: $color-gray;
    }

    &Inner {
      display: flex;
      margin-top: -8px;
      margin-left: -8px;
      flex-wrap: wrap;
    }

    &Outlined {
      border: 1px solid map-get($colors, darker-gray);
      border-radius: 5px;
      background-color: map-get($colors, white);
    }

    &Item {
      display: flex;
      height: 32px;
      padding: 6px 12px;
      border-radius: 6px;
      background-color: #2e30421a;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      &Make {
        padding: 6px 6px;
      }

      &Name {
        margin: 0 8px;
        font-family: $ff-lato;
        font-size: map-get($sizes, smaller);
        font-style: $fs-normal;
        font-weight: $fw-normal;
        line-height: map-get($sizes, big);
        color: map-get($colors, dark-gray);
        flex: none;
        flex-grow: 0;
        order: 0;
      }

      &Gap {
        margin-top: 8px;
        margin-left: 8px;
      }

      &Remove {
        display: flex;
        padding: 0;
        background-color: map-get($colors, white-gray);
        align-items: center;
        cursor: pointer;

        span {
          font-size: map-get($sizes, very-big);
          line-height: 0px;
          transform: rotateZ(45deg);
        }
      }
    }
  }
}

.multiTagSelect {
  position: relative;
  width: 230px;

  &Control {
    display: flex;
    overflow: hidden;
    height: 32px;
    border: 1px solid map-get($colors, darker-gray);
    border-radius: 6px;
    color: map-get($colors, main-black);
    background-color: map-get($colors, white);
    box-shadow: 0 12px 30px 0 rgba(map-get($colors, black), 0.16);
    justify-content: space-between;
  }

  &Input {
    height: 100%;
    padding: 0 10px;
    font-family: $ff-lato;
    font-size: map-get($sizes, regular);
    line-height: 20px;
    border: none;
  }

  &Indicator {
    padding: 0 10px;
    background-color: map-get($colors, white);
  }

  &ActionButton {
    cursor: pointer;
    width: 32px;
    height: 32px;
    padding: 0;
    line-height: 0;
    border-radius: 6px;
    background-color: map-get($colors, light-blue);
  }

  &Menu {
    position: absolute;
    z-index: $z-index-one;
    display: none;
    overflow: hidden;
    width: 230px;
    height: 180px;
    padding: 0;
    border: 1px solid map-get($colors, darker-gray);
    border-radius: 6px;
    background-color: map-get($colors, white);
    box-shadow: 0 12px 30px 0 rgba(map-get($colors, black), 0.16);
    flex-direction: column;

    &Open {
      display: flex;
    }

    &List {
      display: flex;
      overflow-y: scroll;
      height: 150px;
      padding-top: 5px;
      padding-bottom: 5px;
      flex-direction: column;
    }

    &Item {
      display: flex;
      padding: 10px;
      outline: none;
      text-align: left;
      border: none;
      background-color: map-get($colors, white);
      align-items: center;

      &Checkbox {
        width: 14px;
        height: 14px;
        margin-right: 10px;
      }

      &:hover,
      &Selected {
        background-color: rgba($color-secondary-mint, 0.05);
      }

      &Selected {
        color: $color-secondary-mint;
      }

      &Separator {
        height: 1px;
        margin: 0 10px;
        background-color: map-get($colors, darker-gray);
      }
    }

    &SelectAll {
      font-weight: $fw-bold;
      color: map-get($colors, darker-gray);
    }
  }
}

.isPhantom {
  background-color: map-get($colors, green);
  background-image:
    linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.025) 25%,
      transparent 25%,
      transparent 50%,
      rgba(0, 0, 0, 0.025) 50%,
      rgba(0, 0, 0, 0.025) 75%,
      transparent 75%,
      transparent
    );
  background-size: 64px 64px;

  .multiTagSelectMenuItemLabel {
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .multiTagListItemName,
  .multiTagListItemRemove,
  .multiTagSelectMenuItemLabel {
    color: map-get($colors, white);
  }

  &:hover {
    background-color: map-get($colors, green);
  }
}

.notAdvisor {
  background-color: map-get($colors, mint);
  background-image:
    linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.025) 25%,
      transparent 25%,
      transparent 50%,
      rgba(0, 0, 0, 0.025) 50%,
      rgba(0, 0, 0, 0.025) 75%,
      transparent 75%,
      transparent
    );
  background-size: 64px 64px;

  .multiTagListItemName,
  .multiTagListItemRemove,
  .multiTagSelectMenuItemLabel {
    color: map-get($colors, white);
  }
}
