@import '~styles/index.scss';

.modal {
  @include flex-centered;

  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-index-10;
  width: 100%;
  height: 100%;
  background-color: rgba($color-smoke-white, 0.3);
}

.container {
  @include sizing(100% 100%);
  @include max-sizing(1000px 95vh);

  position: relative;
  display: flex;
  overflow-x: hidden;
  margin: auto;
  border-radius: 10px;
  background-color: $color-smoke-white;
  box-shadow: 0 2px 20px 0 $color-black-02;
  flex-direction: column;
}
