.position-relative {
  position: relative;
}

.display-flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.flex-end {
  justify-content: flex-end;
}

.display-flex-column {
  display: flex;
  flex-direction: column;
}

.base-margin {
  margin: 24px;
}

.padding-top {
  padding-top: 16px;
}

.full-width {
  width: 100%;
}

.text-gray {
  color: map-get($colors, font-gray);
}

.text-capitalized {
  text-transform: capitalize;
}

.text-smaller {
  font-size: map-get($sizes, smaller);
}

.text-regular {
  font-size: map-get($sizes, regular);
}

.text-big {
  font-size: map-get($sizes, big);
}

.font-ibm {
  font-family: $ff-ibm;
}

.font-lato {
  font-family: $ff-lato;
}

.text-bold {
  font-weight: $fw-bold;
}

.text-uppercase {
  text-transform: uppercase;
}
