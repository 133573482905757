@import '~styles/index.scss';

.panel {
  :global {
    .conciergePanelBody {
      padding: 0;
    }
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: map-get($colors, darker-gray);
}

.service {
  margin: 0 5px;
  font-size: map-get($sizes, regular);
  color: map-get($colors, main-black);

  &:first-child {
    margin-left: 0;
  }

  .name {
    span {
      font-size: map-get($sizes, bigger);
      font-weight: $fw-bold;
    }
  }

  .info {
    &Label {
      font-size: map-get($sizes, smaller);
      color: map-get($colors, font-gray);
    }

    &Value {
      margin-left: 3px;
      font-size: map-get($sizes, smaller);
    }
  }

  .icon {
    width: 30px;
    height: 30px;
  }

  .accordion {
    border: $default-border;
    border-radius: $big-border-radius;

    &Item {
      @include main-font;

      &Content p {
        padding: 20px;
        font-size: map-get($sizes, smaller);
        color: map-get($colors, darker-gray);
        background-color: map-get($colors, light-gray);
      }

      &:last-child {
        .conciergeBookedServicesAccordionItemHeader {
          border-radius: $big-border-radius;
          border-bottom: none;
        }
      }

      &-open {
        &:last-child {
          .conciergeBookedServicesAccordionItemHeader {
            border-radius: 10px 10px 0 0;
            border-bottom: $default-border;
          }
        }
      }
    }
  }
}

.toggle {
  margin: 0 5px;
  margin-left: auto;
  font-size: map-get($sizes, regular);
  color: map-get($colors, main-black);
  cursor: pointer;
}

.section {
  display: flex;
  padding: 15px;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;

  &Title {
    padding: 15px;
    font-size: map-get($sizes, smaller);
    background-color: map-get($colors, light-gray);
  }

  &Content {
    padding: 15px 15px 5px;
    font-weight: $fw-bold;
  }
}

.item {
  margin-top: 15px;
  margin-bottom: 5px;
  padding: 0 15px 0 15px;

  &:last-child {
    margin-bottom: 15px;
  }

  &Label {
    color: map-get($colors, font-gray);
  }

  &Value {
    font-size: map-get($sizes, smaller);
    color: map-get($colors, main-black);
  }

  &Detail {
    padding: 0 5px 0 15px;
    font-size: map-get($sizes, smaller);
    color: map-get($colors, main-black);
  }
}

.questionBlock {
  padding: 10px 10px;
  background-color: map-get($colors, light-gray);

  &Label,
  &Value,
  &Answer {
    @include secondary-font(map-get($sizes, smaller));
  }

  &Label,
  &Value {
    color: map-get($colors, font-gray);
  }

  &Answer {
    margin-left: 5px;
    color: map-get($colors, main-black);
  }

  &:last-child {
    border-bottom: $default-border;
  }
}
