@import '~styles/index.scss';

.DecisionTree {
  &Buttons {
    display: flex;
    justify-content: flex-end;
  }

  &Countdown {
    @include secondary-font(map-get($sizes, slightly-bigger));

    padding: 16px 32px;

    &Invalid {
      color: map-get($colors, red);
    }
  }

  &Button {
    @include secondary-font(map-get($sizes, slightly-bigger));

    margin-left: 16px;
    padding: 16px 32px;
    line-height: 16px;
    border: none;
    border-radius: 6px;
    color: map-get($colors, white);
    background-color: map-get($colors, blueish-gray);
  }
}
