@import '~styles/index.scss';

.container {
  @include main-font;

  display: grid;
  width: 100%;
  max-width: 1120px;
  grid-template-areas: "choicePanel details";
  grid-template-columns: minmax(600px, 4fr) 2fr;
  column-gap: 25px;
  row-gap: 25px;
  margin: auto;
  align-items: start;
}

.noVehicle {
  @include main-font;

  display: flex;
  width: 100%;
  max-width: 1120px;
  margin: auto;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;

  button {
    margin: 20px 0;
  }
}

.choice {
  grid-area: choicePanel;

  .carouselArrowsContainer {
    padding-bottom: 10px;
  }
}

.page {
  height: calc(100% - 56px);
  min-height: calc(100vh - 56px);

  :global {
    .conciergePageHeaderTitle {
      display: flex;
      width: 100%;
      align-items: baseline;
    }

    .conciergePanel {
      width: 100%;
      margin: 35px 0;
    }
  }
}

.main {
  @include horizontally-centered;
}

.header {
  @include main-font(map-get($sizes, bigger));
}

.card {
  width: 100%;
}

.loading {
  @include main-font(map-get($sizes, bigger));

  width: 100%;
  text-align: center;
  color: map-get($colors, pink);
}

.label {
  @include main-font(map-get($sizes, smaller));

  margin-bottom: 10px;
  color: map-get($colors, font-gray);
}

.value {
  @include main-font;

  text-transform: capitalize;

  &-notTransformed {
    text-transform: none;
  }
}

.barGreen {
  max-width: 1120px;
  margin: 31px auto 0;
  padding: 8px 0;
  font-family: $ff-lato;
  text-align: center;
  border-radius: 10px;
  color: map-get($colors, white);
  background-color: map-get($colors, green2);
  box-shadow: 0 12px 30px 0 rgba(0, 0, 0, 0.16);
}

.barRed {
  max-width: 1120px;
  margin: 31px auto 0;
  padding: 8px 0;
  font-family: $ff-lato;
  text-align: center;
  border-radius: 10px;
  color: map-get($colors, white);
  background-color: map-get($colors, red);
  box-shadow: 0 12px 30px 0 rgba(0, 0, 0, 0.16);
}
