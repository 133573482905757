@import "~styles/index.scss";

.conciergeDatepicker {
  &Wrapper {
    position: relative;
  }

  &InputWrapper {
    display: flex;

    .conciergeInput {
      width: 120px;
    }

    .conciergeInputLabel {
      margin-right: 10px;
      font-size: map-get($sizes, regular);
      line-height: 20px;
    }

    .conciergeInputWrapper {
      display: flex;
      margin: 0;
      align-items: baseline;

      &.datepicker {
        align-items: center;
        justify-content: center;

        .conciergeInputLabel {
          color: map-get($colors, bluey-grey);
        }

        .conciergeInput {
          display: flex;
          width: 100%;
          padding: 0;
          background: map-get($colors, white);
          align-items: center;

          .calendarIcon {
            margin: 0 0.5rem;
          }

          input {
            width: 120px;
            border: none;
            background: transparent;

            &:disabled {
              color: map-get($colors, font-gray);
            }
          }
        }
      }

      &:first-child {
        margin-right: 10px;
      }
    }
  }

  &CalendarContainer {
    position: absolute;
    right: 0;
    z-index: $z-index-1;
    display: none;
    width: 312px;
    min-width: 312px;
    max-width: 312px;
    border-radius: 4px;

    &-open {
      display: block;
    }

    .conciergePanelBody {
      padding: 16px;
    }
  }

  &ButtonsWrapper {
    display: flex;
    padding-top: 14px;
    justify-content: space-between;

    .conciergeButton {
      line-height: 22px;
    }

    :not(:first-child) {
      margin-left: 8px;
    }
  }
}
