@import "./colors.scss";
@import "./typography.scss";

.box {
  border: map-get($colors, light-gray) 1px solid;
  border-radius: 10px;
  background: map-get($colors, white);
  box-shadow: 0 4px 10px 0 rgba(map-get($colors, shadow-gray), 0.08);

  & + & {
    margin-top: 10px;
  }

  & > div:first-child,
  &__header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  & > div:last-child,
  &__body {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  &__header {
    padding: 12px 14px;
    background: map-get($colors, darker-white);
  }

  &__body {
    padding: 15px;
  }

  &__body:empty,
  &__box_nopad {
    padding: 0;
  }

  &__body + &__header,
  &__header + &__header {
    border-top: map-get($colors, light-gray) 1px solid;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &__header + &__body {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.data {
  font-family: $ff-lato;
  font-size: map-get($sizes, smaller);

  & + & {
    margin-top: 5px;
  }

  &Label {
    color: map-get($colors, silver-gray);
  }
}
