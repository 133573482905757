@import '~styles/index.scss';

.conciergeSettingsPageUsersPanel {
  .conciergePanelBody {
    padding: 0;
  }

  .ReactTable {
    border: none;
    border-radius: 0;
    border-bottom: $default-border;

    .rt-table {
      overflow: visible;
    }

    .rt-tbody {
      overflow: visible;

      .rt-tr {
        align-items: baseline;
      }

      .rt-td {
        padding-top: 12px;
        white-space: break-spaces;
        word-break: break-all;
      }
    }

    .rt-noData {
      top: 43%;
    }
  }

  &ButtonSection {
    display: flex;
    margin: 20px;
    justify-content: flex-end;
  }

  &Button-add {
    @include secondary-font(map-get($sizes, slightly-bigger));

    padding: 16px 32px;
    line-height: 16px;
    border: none;
    border-radius: 6px;
    color: map-get($colors, white);
    background-color: map-get($colors, blueish-gray);

    &:hover {
      box-shadow: $default-box-shadow;
    }
  }

  .conciergeSettingsAddUserModal {
    top: calc(50% - 200.5px);
    height: auto;

    &Footer {
      padding-bottom: 20px;
    }
  }
}

.usersSearch {
  position: relative;
  display: flex;
  width: 300px;
  height: fit-content;
  padding: 10px;
  border: $default-border;
  border-radius: $small-border-radius;
  background-color: map-get($colors, white);
  justify-content: space-between;

  &Input {
    @include main-font;

    width: 100%;
    outline: none;
    border: none;

    &::placeholder {
      color: map-get($colors, font-gray);
    }
  }

  &Icon {
    cursor: pointer;
  }
}
