@import '~styles/index.scss';

.container {
  :global {
    .conciergePanelBody {
      width: 100%;
      padding: 0;
    }
  }
}

.header {
  padding: 15px;
  font-size: map-get($sizes, smaller);
  border-top: 1px solid map-get($colors, darker-gray);
  background-color: map-get($colors, light-gray);
}

.section {
  display: flex;
  padding: 15px;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
}

.service {
  margin: 0 5px;
  font-size: map-get($sizes, regular);
  color: map-get($colors, main-black);

  .name {
    display: flex;
    align-items: center;
  }

  .icon {
    width: 30px;
    height: 30px;
  }
}

.toggle {
  margin-right: 5px;
  margin-left: auto;
}

.price {
  display: flex;
  align-items: center;

  .label {
    font-size: map-get($sizes, smaller);
    color: map-get($colors, font-gray);
  }

  .value {
    margin-left: 5px;
    font-size: map-get($sizes, smaller);
  }
}

.accordion {
  @include main-font;

  border: $default-border;
  border-radius: $big-border-radius;

  &Block {
    padding: 10px 10px;
    background-color: map-get($colors, light-gray);

    &Label,
    &Value,
    &Answer {
      font-family: $ff-lato;
      font-size: map-get($sizes, smaller);
    }

    &Label,
    &Value {
      color: map-get($colors, font-gray);
    }

    &Answer {
      margin-left: 5px;
      color: map-get($colors, main-black);
    }

    &:last-child {
      border-bottom: $default-border;
    }
  }

  &Content p {
    padding: 20px;
    font-size: map-get($sizes, smaller);
    color: map-get($colors, darker-gray);
    background-color: map-get($colors, light-gray);
  }

  &:last-child {
    :global {
      .conciergeBookedServicesAccordionItemHeader {
        border-radius: $big-border-radius;
        border-bottom: none;
      }
    }
  }

  &-open {
    &:last-child {
      :global {
        .conciergeBookedServicesAccordionItemHeader {
          border-radius: 10px 10px 0 0;
          border-bottom: $default-border;
        }
      }
    }
  }
}

.total {
  padding: 15px 15px 5px;
  font-size: map-get($sizes, regular);
  font-weight: $fw-bold;
}

.details {
  padding: 0 15px 15px 15px;

  .container {
    display: flex;
    flex-direction: column;
  }

  .item {
    margin-top: 15px;
    margin-bottom: 5px;
  }

  .label {
    font-size: map-get($sizes, smaller);
    color: map-get($colors, font-gray);
  }

  .value {
    font-size: map-get($sizes, smaller);
  }
}
